import moment from 'moment';

export const LIST_MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

export const defaultMonthlyData = [
  {
    header: 'OB Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Soil Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Mud Original Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Total Waste Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'OB Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Soil Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Mud Original Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Total Waste Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'OB Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Soil Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Mud Original Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Total Waste Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Total Waste',
    unit: 'BCM',
    code: 'waste',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Opening Inventory',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Coal Expose',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Coal to Mine',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Cap Unit Coal',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Coal Getting',
    unit: 'tonnes',
    code: 'coal',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Closing Inventory',
    unit: 'tonnes',
    code: 'sr',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'SR Expose',
    unit: 'BCM/ton',
    code: 'sr',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'SR Inpit (CG)',
    unit: 'BCM/ton',
    code: 'sr',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'SR All',
    unit: 'BCM/ton',
    code: 'sr',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Coal',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance OB Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Soil Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Mud Original Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Waste Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance OB Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Soil Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Mud Original Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Waste Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance OB Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Soil Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Mud Original Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Waste Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    header: 'Distance Waste All',
    unit: 'meter',
    code: 'distance',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'ASH',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'CV_ADB',
    unit: 'kcal/kg',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'CV_GAR',
    unit: 'kcal/kg',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'FC',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'IM',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'RD_IS',
    unit: 'ton/bcm',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'TM',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'TS',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'VM',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  },
  {
    code: 'quality',
    header: 'Quality',
    unit: '',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    Jan: null,
    Feb: null,
    Mar: null,
    Apr: null,
    May: null,
    Jun: null,
    Jul: null,
    Aug: null,
    Sep: null,
    Oct: null,
    Nov: null,
    Dec: null,
    year: null
  }
];


const parseNumber = (str) => {
  if (!str) return null;

  str = String(str);
  const number = parseFloat(str.replace(/\./g, '').replace(',', '.'));
  return number === 0 ? null : number;
};

export const formatToTwoDecimals = (value) => {
  if (isNaN(value) || value === null || value === undefined) {
    return '0,00';
  }
  return value % 1 === 0 ? `${value},00` : value.toFixed(2).replace('.', ',');
};

export const convertStringToFormattedNumber = (str) => {
  if (!str) return null;
  if (typeof str === "number") return str;

  if (typeof str !== 'string') {
    console.warn('convertStringToFormattedNumber: str is not a string', str);
    // return 0;
  };

  const cleanedStr = str.replace(/\./g, '').replace(',', '.');
  return Number(cleanedStr);
};

export const revertFormattedNumberToString = (num) => {
  if (!num) return null;
  if (typeof num === 'string') return num;

  if (typeof num !== "number") {
    console.warn('revertFormattedNumberToString: num is not a number', num);
    // return "0";
  };

  const str = num.toString();
  const [ integerPart, decimalPart ] = str.split(".");
  const formattedStr =
    integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
    (decimalPart ? `,${decimalPart}` : "");
  return formattedStr;
};

export const updatedCellList = [
  {
    header: 'OB Inpit',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Inpit' ],
    total: [
      'Total Waste',
      'SR Inpit (CG)',
      'SR All',
      'Distance Waste Inpit',
      'SR Expose',
      'Distance Waste All'
    ]
  },
  {
    header: 'Soil Inpit',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Inpit' ],
    total: [
      'Total Waste',
      'SR Inpit (CG)',
      'SR All',
      'Distance Waste Inpit',
      'SR Expose',
      'Distance Waste All'
    ]
  },
  {
    header: 'Mud Original Inpit',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Inpit' ],
    total: [
      'Total Waste',
      'SR Inpit (CG)',
      'SR All',
      'Distance Waste Inpit',
      'SR Expose',
      'Distance Waste All'
    ]
  },
  // {
  //   header: 'Total Waste Inpit',
  //   updateOtherHeader: [],
  //   subTotal: [],
  //   total: [
  //     'Total Waste',
  //   ]
  // },
  {
    header: 'OB Outpit',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Outpit' ],
    total: [
      'Total Waste',
      'SR All',
      'Distance Waste All',
      'Distance Waste Outpit'
    ]
  },
  {
    header: 'Soil Outpit',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Outpit' ],
    total: [
      'Total Waste',
      'SR All',
      'Distance Waste All',
      'Distance Waste Outpit'
    ]
  },
  {
    header: 'Mud Original Outpit',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Outpit' ],
    total: [
      'Total Waste',
      'SR All',
      'Distance Waste All',
      'Distance Waste Outpit'
    ]
  },
  {
    header: 'OB Project',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Project' ],
    total: [
      'Total Waste',
      'SR All',
      'Distance Waste All',
      'Distance Waste Project'
    ]
  },
  {
    header: 'Soil Project',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Project' ],
    total: [
      'Total Waste',
      'SR All',
      'Distance Waste All',
      'Distance Waste Project'
    ]
  },
  {
    header: 'Mud Original Project',
    updateOtherHeader: [],
    subTotal: [ 'Total Waste Project' ],
    total: [
      'Total Waste',
      'SR All',
      'Distance Waste All',
      'Distance Waste Project'
    ]
  },
  {
    header: 'Opening Inventory',
    updateOtherHeader: [
      'Coal to Mine',
      'SR Expose',
      'Closing Inventory' ],
    subTotal: [],
    total: [
      'Coal Getting',
      'SR All',
      'SR Inpit (CG)',
      'Closing Inventory'
    ]
  },
  {
    header: 'Coal Expose',
    updateOtherHeader: [ 'Coal to Mine',
      'SR Expose',
      'Closing Inventory' ],
    subTotal: [],
    total: [
      'Coal Getting',
      'SR All',
      'SR Inpit (CG)',
      'Closing Inventory'
    ]
  },
  {
    header: 'Cap Unit Coal',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Coal Getting',
      'SR All',
      'SR Inpit (CG)',
      'Closing Inventory'
    ]
  },
  {
    header: 'Distance OB Inpit',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Inpit' ],
    total: [ 'Distance Waste All' ]
  },
  {
    header: 'Distance Soil Inpit',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Inpit' ],
    total: [ 'Distance Waste All' ]
  },
  {
    header: 'Distance Mud Original Inpit',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Inpit' ],
    total: [ 'Distance Waste All' ]
  },
  {
    header: 'Distance OB Outpit',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Outpit' ],
    total: [ 'Distance Waste All', 'Distance Waste All' ]
  },
  {
    header: 'Distance Soil Outpit',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Outpit' ],
    total: [ 'Distance Waste All', 'Distance Waste All' ]
  },
  {
    header: 'Distance Mud Original Outpit',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Outpit' ],
    total: [ 'Distance Waste All', 'Distance Waste All' ]
  },
  {
    header: 'Distance OB Project',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Project' ],
    total: [ 'Distance Waste All' ]
  },
  {
    header: 'Distance Soil Project',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Project' ],
    total: [ 'Distance Waste All' ]
  },
  {
    header: 'Distance Mud Original Project',
    updateOtherHeader: [],
    subTotal: [ 'Distance Waste Project' ],
    total: [ 'Distance Waste All' ]
  },
  {
    header: 'ASH',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'CV_ADB',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'CV_GAR',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'FC',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'IM',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'RD_IS',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'TM',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'TS',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
  {
    header: 'VM',
    updateOtherHeader: [],
    subTotal: [],
    total: [ 'Quality' ]
  },
];

export const sumList = {
  "Total Waste Inpit": {
    listDependency: [
      "OB Inpit",
      "Soil Inpit",
      "Mud Original Inpit"
    ],
    formula: (data) => {
      // console.log('Total Waste Inpit ♾️ file: STPTable.js:384 ♾️ TOTALFINAL:', data);
      return (data[ 'OB Inpit' ]) + (data[ 'Soil Inpit' ]) + (data[ 'Mud Original Inpit' ]);
    }
  },
  "Total Waste Outpit": {
    listDependency: [
      "OB Outpit",
      "Soil Outpit",
      "Mud Original Outpit"
    ],
    formula: (data) => {
      // console.log('Total Waste Outpit ♾️ file: STPTable.js:394 ♾️ TOTALFINAL:', data);
      return (data[ 'OB Outpit' ]) + (data[ 'Soil Outpit' ]) + (data[ 'Mud Original Outpit' ]);
    }
  },
  "Total Waste Project": {
    listDependency: [
      "OB Project",
      "Soil Project",
      "Mud Original Project"
    ],
    formula: (data) => {
      // console.log('Total Waste Project ♾️ file: STPTable.js:405 ♾️ TOTALFINAL:', data);
      return (data[ 'OB Project' ]) + (data[ 'Soil Project' ]) + (data[ 'Mud Original Project' ]);
    }
  },
  "Total Waste": {
    listDependency: [
      "Total Waste Inpit",
      "Total Waste Outpit",
      "Total Waste Project"
    ],
    formula: (data) => {
      // console.log('Total Waste ♾️ file: STPTable.js:416 ♾️ TOTALFINAL:', data);
      return (data[ 'Total Waste Inpit' ]) + (data[ 'Total Waste Outpit' ]) + (data[ 'Total Waste Project' ]);
    }
  },
  "Coal to Mine": {
    listDependency: [
      "Opening Inventory",
      "Coal Expose"
    ],
    formula: (data) => {
      // console.log('Coal to Mine ♾️ file: STPTable.js:426 ♾️ TOTALFINAL:', data);
      return (data[ 'Opening Inventory' ]) + (data[ 'Coal Expose' ]);
    }
  },
  "Coal Getting": {
    listDependency: [
      "Coal to Mine",
      "Cap Unit Coal"
    ],
    formula: (data) => {
      // console.log('Coal Getting ♾️ file: STPTable.js:436 ♾️ TOTALFINAL:', data);
      return Math.min((data[ 'Coal to Mine' ]), (data[ 'Cap Unit Coal' ]));
    }
  },
  "Closing Inventory": {
    listDependency: [
      "Opening Inventory",
      "Coal Expose",
      "Coal Getting"
    ],
    formula: (data) => {
      // console.log('Closing Inventory ♾️ file: STPTable.js:447 ♾️ TOTALFINAL:', data);
      return (data[ 'Opening Inventory' ]) + (data[ 'Coal Expose' ]) - (data[ 'Coal Getting' ]);
    }
  },
  "Distance Waste Inpit": {
    listDependency: [
      "Distance OB Inpit",
      "Distance Soil Inpit",
      "Distance Mud Original Inpit",
      "OB Inpit",
      "Soil Inpit",
      "Mud Original Inpit",
      "Total Waste Inpit"
    ],
    formula: (data) => {
      // console.log('Distance Waste Inpit ♾️ file: STPTable.js:462 ♾️ TOTALFINAL:', data);
      return ((data[ 'Distance OB Inpit' ]) * (data[ 'OB Inpit' ]) +
        (data[ 'Distance Soil Inpit' ]) * (data[ 'Soil Inpit' ]) +
        (data[ 'Distance Mud Original Inpit' ]) * (data[ 'Mud Original Inpit' ])) / (data[ 'Total Waste Inpit' ]);
    }
  },
  "Distance Waste Outpit": {
    listDependency: [
      "Distance OB Outpit",
      "Distance Soil Outpit",
      "Distance Mud Original Outpit",
      "OB Outpit",
      "Soil Outpit",
      "Mud Original Outpit",
      "Total Waste Outpit"
    ],
    formula: (data) => {
      // console.log('Distance Waste Outpit ♾️ file: STPTable.js:479 ♾️ TOTALFINAL:', data);
      return ((data[ 'Distance OB Outpit' ]) * (data[ 'OB Outpit' ]) +
        (data[ 'Distance Soil Outpit' ]) * (data[ 'Soil Outpit' ]) +
        (data[ 'Distance Mud Original Outpit' ]) * (data[ 'Mud Original Outpit' ])) / (data[ 'Total Waste Outpit' ]);
    }
  },
  "Distance Waste Project": {
    listDependency: [
      "Distance OB Project",
      "Distance Soil Project",
      "Distance Mud Original Project",
      "OB Project",
      "Soil Project",
      "Mud Original Project",
      "Total Waste Project"
    ],
    formula: (data) => {
      // console.log('Distance Waste Project ♾️ file: STPTable.js:496 ♾️ TOTALFINAL:', data);
      return ((data[ 'Distance OB Project' ]) * (data[ 'OB Project' ]) +
        (data[ 'Distance Soil Project' ]) * (data[ 'Soil Project' ]) +
        (data[ 'Distance Mud Original Project' ]) * (data[ 'Mud Original Project' ])) / (data[ 'Total Waste Project' ]);
    }
  },
  "SR Inpit (CG)": {
    listDependency: [
      "Total Waste Inpit",
      "Coal Getting"
    ],
    formula: (data) => {
      // console.log('SR Inpit (CG) ♾️ file: STPTable.js:508 ♾️ TOTALFINAL:', data);
      return (data[ 'Total Waste Inpit' ]) / (data[ 'Coal Getting' ]);
    }
  },
  "SR All": {
    listDependency: [
      "Total Waste",
      "Coal Getting"
    ],
    formula: (data) => {
      // console.log('SR All ♾️ file: STPTable.js:518 ♾️ TOTALFINAL:', data);
      return (data[ 'Total Waste' ]) / (data[ 'Coal Getting' ]);
    }
  },
  "Distance Waste All": {
    listDependency: [
      "Distance Waste Inpit",
      "Total Waste Inpit",
      "Distance Waste Outpit",
      "Total Waste Outpit",
      "Distance Waste Project",
      "Total Waste Project",
      "Total Waste"
    ],
    formula: (data) => {
      // console.log('Distance Waste All ♾️ file: STPTable.js:533 ♾️ TOTALFINAL:', data);
      return ((data[ 'Distance Waste Inpit' ]) * (data[ 'Total Waste Inpit' ]) +
        (data[ 'Distance Waste Outpit' ]) * (data[ 'Total Waste Outpit' ]) +
        (data[ 'Distance Waste Project' ]) * (data[ 'Total Waste Project' ])) / (data[ 'Total Waste' ]);
    }
  },
  "Quality": {
    listDependency: [
      "ASH",
      "CV_ADB",
      "CV_GAR",
      "FC",
      "IM",
      "RD_IS",
      "TM",
      "TS",
      "VM"
    ],
    formula: (data) => {
      // console.log('Quality ♾️ file: STPTable.js:552 ♾️ TOTALFINAL:', data);
      return (data[ 'ASH' ]) + (data[ 'CV_ADB' ]) + (data[ 'CV_GAR' ]) +
        (data[ 'FC' ]) + (data[ 'IM' ]) + (data[ 'RD_IS' ]) +
        (data[ 'TM' ]) + (data[ 'TS' ]) + (data[ 'VM' ]);
    }
  },
  "SR Expose": {
    listDependency: [
      "Total Waste Inpit",
      "Coal Expose"
    ],
    formula: (data) => {
      // console.log('SR Expose ♾️ file: STPTable.js:551 ♾️ TOTALFINAL:', data);
      return (data[ 'Total Waste Inpit' ]) / (data[ 'Coal Expose' ]);
    }
  }
};

export const filterDataByHeaders = (data, headers) => {
  return data.filter(item => headers.includes(item.header));
};

const arrayToObject = (dataArray) => {
  return dataArray.reduce((obj, item) => {
    return {
      ...obj,
      [ item.header ]: item,
    };
  }, {});
};

const filterDataByKey = (getData, filterByKey) => {
  let result = {};
  for (let key in getData) {
    result[ key ] = getData[ key ][ filterByKey ];
  }
  return result;
};

const sumValues = (data) => {
  return Object.values(data).reduce((a, b) => a + b, 0);
};


/* {
  headersInCurrentDataOnly: [
    'Waste Removal',
    'Mud',
    'Waste Haul Distance All',
    'Waste Haul Distance Inpit',
    'Waste Haul Distance Outpit',
    'Coal Exposed',
    'Capacity Unit Coal',
    'SR Inpit',
    'SR Exposed'
  ],
  headersInDefaultMonthlyDataOnly: [
    'Soil Inpit',
    'Mud Original Inpit',
    'Total Waste Inpit',
    'Soil Outpit',
    'Mud Original Outpit',
    'Total Waste Outpit',
    'OB Project',
    'Soil Project',
    'Mud Original Project',
    'Total Waste Project',
    'Total Waste',
    'Coal Expose',
    'Coal to Mine',
    'Cap Unit Coal',
    'SR Expose',
    'SR Inpit (CG)',
    'Distance Coal',
    'Distance OB Inpit',
    'Distance Soil Inpit',
    'Distance Mud Original Inpit',
    'Distance Waste Inpit',
    'Distance OB Outpit',
    'Distance Soil Outpit',
    'Distance Mud Original Outpit',
    'Distance Waste Outpit',
    'Distance OB Project',
    'Distance Soil Project',
    'Distance Mud Original Project',
    'Distance Waste Project',
    'Distance Waste All'
  ]
} */

/*
Distance Waste Inpit =	Waste Haul Distance Inpit
Distance Waste Outpit =	Waste Haul Distance Outpit
*/



const ALIAS = {
  // 'OB Inpit': 'OB Inpit',
  // 'Soil Inpit': 'OB Inpit',
  // 'Mud Original Inpit': 'Mud',
  //! 'Total Waste Inpit': 'Total Waste Inpit',

  // 'OB Outpit': 'OB Outpit',
  // 'Soil Outpit': 'OB Outpit',
  // 'Mud Original Outpit': 'Mud',
  //! 'Total Waste Outpit': 'Total Waste Outpit',
  // 'OB Project': 'OB Outpit',
  // 'Soil Project': 'OB Outpit',
  // 'Mud Original Project': 'Mud',
  //! 'Total Waste Project': 'Total Waste Project',
  //! 'Total Waste': 'Total Waste',

  'Opening Inventory': 'Opening Inventory',
  'Coal Expose': 'Coal Exposed',
  //! 'Coal to Mine': 'Coal to Mine',
  'Cap Unit Coal': 'Capacity Unit Coal',
  'Coal Getting': 'Coal Getting',
  'Closing Inventory': 'Closing Inventory',
  'SR Expose': 'SR Exposed',
  'SR Inpit (CG)': 'SR Inpit',
  'SR All': 'SR All',

  'Distance Coal': 'Distance Coal',
  // 'Distance OB Inpit': 'Waste Haul Distance Inpit',
  // 'Distance Soil Inpit': 'Waste Haul Distance Inpit',
  // 'Distance Mud Original Inpit': 'Waste Haul Distance Inpit',
  //! 'Distance Waste Inpit': "Waste Haul Distance Inpit",

  // 'Distance OB Outpit': 'Waste Haul Distance Outpit',
  // 'Distance Soil Outpit': 'Waste Haul Distance Outpit',
  // 'Distance Mud Original Outpit': 'Waste Haul Distance Outpit',
  //! 'Distance Waste Outpit': "Waste Haul Distance Outpit",

  // 'Distance OB Project': 'Waste Haul Distance Outpit',
  // 'Distance Soil Project': 'Waste Haul Distance Outpit',
  // 'Distance Mud Original Project': 'Waste Haul Distance Outpit',
  //! 'Distance Waste Project': 'Distance Waste Project',

  //! 'Distance Waste All': 'Distance Waste All',
  'ASH': 'ASH',
  'CV_ADB': 'CV_ADB',
  'CV_GAR': 'CV_GAR',
  'FC': 'FC',
  'IM': 'IM',
  'RD_IS': 'RD_IS',
  'TM': 'TM',
  'TS': 'TS',
  'VM': 'VM',
  'Quality': 'Quality'
};

const getArrayTotal = (arr) => arr.reduce((acc, val) => acc + val, 0);

const multiplyArrays = (arr1, arr2) => {
  return arr1.reduce((acc, val, index) => acc + val * arr2[ index ], 0);
};

const yearlySumFormulas = {
  'Total Waste': {
    listDependency: {
      'Total Waste Inpit': [...LIST_MONTHS],
      'Total Waste Outpit': [...LIST_MONTHS],
      'Total Waste Project': [...LIST_MONTHS]
    },
    formula: (data) => {
      const sumWasteInpit = getArrayTotal(data[ 'Total Waste Inpit' ]);
      const sumWasteOutpit = getArrayTotal(data[ 'Total Waste Outpit' ]);
      const sumWasteProject = getArrayTotal(data[ 'Total Waste Project' ]);

      return sumWasteInpit + sumWasteOutpit + sumWasteProject;
    }
  },
  'Opening Inventory': {
    listDependency: {
      'Opening Inventory': [ 'Jan' ],
    },
    formula: (data) => {
      return getArrayTotal(data[ 'Opening Inventory' ]);
    }
  },
  'Closing Inventory': {
    listDependency: {
      'Closing Inventory': [ 'Dec' ],
    },
    formula: (data) => {
      return getArrayTotal(data[ 'Closing Inventory' ]);
    }
  },
  'SR Expose': {
    listDependency: {
      'Total Waste Inpit': [...LIST_MONTHS],
      'Coal Expose': [...LIST_MONTHS]
    },
    formula: (data) => {
      const sumWasteInpit = getArrayTotal(data[ 'Total Waste Inpit' ]);
      const sumCoalExpose = getArrayTotal(data[ 'Coal Expose' ]);

      return sumWasteInpit / sumCoalExpose;
    }
  },
  'SR Inpit (CG)': {
    listDependency: {
      'Total Waste Inpit': [...LIST_MONTHS],
      'Coal Getting': [...LIST_MONTHS]
    },
    formula: (data) => {
      const sumWasteInpit = getArrayTotal(data[ 'Total Waste Inpit' ]);
      const sumCoalGetting = getArrayTotal(data[ 'Coal Getting' ]);

      return sumWasteInpit / sumCoalGetting;
    }
  },
  'SR All': {
    listDependency: {
      'Total Waste': [...LIST_MONTHS],
      'Coal Getting': [...LIST_MONTHS]
    },
    formula: (data) => {
      const sumTotalWaste = getArrayTotal(data[ 'Total Waste' ]);
      const sumCoalGetting = getArrayTotal(data[ 'Coal Getting' ]);

      return sumTotalWaste / sumCoalGetting;
    }
  },
  'Distance Coal': {
    listDependency: {
      'Distance Coal': [ ...LIST_MONTHS ],
      'Coal Getting': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceCoal = data[ 'Distance Coal' ];
      const dataCoalGetting = data[ 'Coal Getting' ];
      const totalCoalGetting = getArrayTotal(dataCoalGetting);

      const calculateFormula = (distanceCoal, coalGetting, totalCoalGetting) => {
        try {
          const sumProductResult = multiplyArrays(distanceCoal, coalGetting);
          return sumProductResult / totalCoalGetting;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceCoal, dataCoalGetting, totalCoalGetting);
    }
  },
  'Distance OB Inpit': {
    listDependency: {
      'Distance OB Inpit': [ ...LIST_MONTHS ],
      'OB Inpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceOBInpit = data[ 'Distance OB Inpit' ];
      const dataOBInpit = data[ 'OB Inpit' ];
      const totalOBInpit = getArrayTotal(dataOBInpit);

      const calculateFormula = (distanceOBInpit, obInpit, totalOBInpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceOBInpit, obInpit);
          return sumProductResult / totalOBInpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceOBInpit, dataOBInpit, totalOBInpit);
    }
  },
  'Distance Soil Inpit': {
    listDependency: {
      'Distance Soil Inpit': [ ...LIST_MONTHS ],
      'Soil Inpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceSoilInpit = data[ 'Distance Soil Inpit' ];
      const dataSoilInpit = data[ 'Soil Inpit' ];
      const totalSoilInpit = getArrayTotal(dataSoilInpit);

      const calculateFormula = (distanceSoilInpit, soilInpit, totalSoilInpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceSoilInpit, soilInpit);
          return sumProductResult / totalSoilInpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceSoilInpit, dataSoilInpit, totalSoilInpit);
    }
  },
  'Distance Mud Original Inpit': {
    listDependency: {
      'Distance Mud Original Inpit': [ ...LIST_MONTHS ],
      'Mud Original Inpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceMudOriginalInpit = data[ 'Distance Mud Original Inpit' ];
      const dataMudOriginalInpit = data[ 'Mud Original Inpit' ];
      const totalMudOriginalInpit = getArrayTotal(dataMudOriginalInpit);

      const calculateFormula = (distanceMudOriginalInpit, mudOriginalInpit, totalMudOriginalInpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceMudOriginalInpit, mudOriginalInpit);
          return sumProductResult / totalMudOriginalInpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceMudOriginalInpit, dataMudOriginalInpit, totalMudOriginalInpit);
    }
  },
  'Distance Waste Inpit': {
    listDependency: {
      'Distance Waste Inpit': [ ...LIST_MONTHS ],
      'Total Waste Inpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceWasteInpit = data[ 'Distance Waste Inpit' ];
      const dataTotalWasteInpit = data[ 'Total Waste Inpit' ];
      const totalWasteInpit = getArrayTotal(dataTotalWasteInpit);

      const calculateFormula = (distanceWasteInpit, wasteInpit, totalWasteInpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceWasteInpit, wasteInpit);
          return sumProductResult / totalWasteInpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceWasteInpit, dataTotalWasteInpit, totalWasteInpit);
    }
  },
  'Distance OB Outpit': {
    listDependency: {
      'Distance OB Outpit': [ ...LIST_MONTHS ],
      'OB Outpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceOBOutpit = data[ 'Distance OB Outpit' ];
      const dataOBOutpit = data[ 'OB Outpit' ];
      const totalOBOutpit = getArrayTotal(dataOBOutpit);

      const calculateFormula = (distanceOBOutpit, obOutpit, totalOBOutpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceOBOutpit, obOutpit);
          return sumProductResult / totalOBOutpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceOBOutpit, dataOBOutpit, totalOBOutpit);
    }
  },
  'Distance Soil Outpit': {
    listDependency: {
      'Distance Soil Outpit': [ ...LIST_MONTHS ],
      'Soil Outpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceSoilOutpit = data[ 'Distance Soil Outpit' ];
      const dataSoilOutpit = data[ 'Soil Outpit' ];
      const totalSoilOutpit = getArrayTotal(dataSoilOutpit);

      const calculateFormula = (distanceSoilOutpit, soilOutpit, totalSoilOutpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceSoilOutpit, soilOutpit);
          return sumProductResult / totalSoilOutpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceSoilOutpit, dataSoilOutpit, totalSoilOutpit);
    }
  },
  'Distance Mud Original Outpit': {
    listDependency: {
      'Distance Mud Original Outpit': [ ...LIST_MONTHS ],
      'Mud Original Outpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceMudOriginalOutpit = data[ 'Distance Mud Original Outpit' ];
      const dataMudOriginalOutpit = data[ 'Mud Original Outpit' ];
      const totalMudOriginalOutpit = getArrayTotal(dataMudOriginalOutpit);

      const calculateFormula = (distanceMudOriginalOutpit, mudOriginalOutpit, totalMudOriginalOutpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceMudOriginalOutpit, mudOriginalOutpit);
          return sumProductResult / totalMudOriginalOutpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceMudOriginalOutpit, dataMudOriginalOutpit, totalMudOriginalOutpit);
    }
  },
  'Distance Waste Outpit': {
    listDependency: {
      'Distance Waste Outpit': [ ...LIST_MONTHS ],
      'Total Waste Outpit': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceWasteOutpit = data[ 'Distance Waste Outpit' ];
      const dataTotalWasteOutpit = data[ 'Total Waste Outpit' ];
      const totalWasteOutpit = getArrayTotal(dataTotalWasteOutpit);

      const calculateFormula = (distanceWasteOutpit, wasteOutpit, totalWasteOutpit) => {
        try {
          const sumProductResult = multiplyArrays(distanceWasteOutpit, wasteOutpit);
          return sumProductResult / totalWasteOutpit;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceWasteOutpit, dataTotalWasteOutpit, totalWasteOutpit);
    }
  },
  'Distance OB Project': {
    listDependency: {
      'Distance OB Project': [ ...LIST_MONTHS ],
      'OB Project': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceOBProject = data[ 'Distance OB Project' ];
      const dataOBProject = data[ 'OB Project' ];
      const totalOBProject = getArrayTotal(dataOBProject);

      const calculateFormula = (distanceOBProject, obProject, totalOBProject) => {
        try {
          const sumProductResult = multiplyArrays(distanceOBProject, obProject);
          return sumProductResult / totalOBProject;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceOBProject, dataOBProject, totalOBProject);
    }
  },
  'Distance Soil Project': {
    listDependency: {
      'Distance Soil Project': [ ...LIST_MONTHS ],
      'Soil Project': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceSoilProject = data[ 'Distance Soil Project' ];
      const dataSoilProject = data[ 'Soil Project' ];
      const totalSoilProject = getArrayTotal(dataSoilProject);

      const calculateFormula = (distanceSoilProject, soilProject, totalSoilProject) => {
        try {
          const sumProductResult = multiplyArrays(distanceSoilProject, soilProject);
          return sumProductResult / totalSoilProject;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceSoilProject, dataSoilProject, totalSoilProject);
    }
  },
  'Distance Mud Original Project': {
    listDependency: {
      'Distance Mud Original Project': [ ...LIST_MONTHS ],
      'Mud Original Project': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceMudOriginalProject = data[ 'Distance Mud Original Project' ];
      const dataMudOriginalProject = data[ 'Mud Original Project' ];
      const totalMudOriginalProject = getArrayTotal(dataMudOriginalProject);

      const calculateFormula = (distanceMudOriginalProject, mudOriginalProject, totalMudOriginalProject) => {
        try {
          const sumProductResult = multiplyArrays(distanceMudOriginalProject, mudOriginalProject);
          return sumProductResult / totalMudOriginalProject;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceMudOriginalProject, dataMudOriginalProject, totalMudOriginalProject);
    }
  },
  'Distance Waste Project': {
    listDependency: {
      'Distance Waste Project': [ ...LIST_MONTHS ],
      'Total Waste Project': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceWasteProject = data[ 'Distance Waste Project' ];
      const dataTotalWasteProject = data[ 'Total Waste Project' ];
      const totalWasteProject = getArrayTotal(dataTotalWasteProject);

      const calculateFormula = (distanceWasteProject, wasteProject, totalWasteProject) => {
        try {
          const sumProductResult = multiplyArrays(distanceWasteProject, wasteProject);
          return sumProductResult / totalWasteProject;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceWasteProject, dataTotalWasteProject, totalWasteProject);
    }
  },
  'Distance Waste All': {
    listDependency: {
      'Distance Waste All': [ ...LIST_MONTHS ],
      'Total Waste': [ ...LIST_MONTHS ],
    },
    formula: (data) => {
      const dataDistanceWasteAll = data[ 'Distance Waste All' ];
      const dataTotalWaste = data[ 'Total Waste' ];
      const totalWaste = getArrayTotal(dataTotalWaste);

      const calculateFormula = (distanceWasteAll, wasteAll, totalWaste) => {
        try {
          const sumProductResult = multiplyArrays(distanceWasteAll, wasteAll);
          return sumProductResult / totalWaste;
        } catch (error) {
          return 0;
        }
      };

      return calculateFormula(dataDistanceWasteAll, dataTotalWaste, totalWaste);
    }
  },
};

const extractValues = (listDependency, data) => {
  const result = {};
  const dataMap = data.reduce((acc, item) => {
    acc[ item.header ] = item;
    return acc;
  }, {});

  Object.keys(listDependency).forEach(key => {
    const dependency = listDependency[ key ];
    const dataObject = dataMap[ key ];

    if (dataObject) {
      result[ key ] = dependency.map(dep => {
        const value = dataObject[ dep ];
        return (typeof value === 'number' && !isNaN(value)) ? value : 0;
      });
    }
  });

  return result;
};

export const sumYear = (data) => {
  if (!Array.isArray(data)) return data;

  const validKeys = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
    "May",
    "Aug",
    "Oct",
    "Dec"
  ];

  return data.map((item) => {
    if (yearlySumFormulas[ item.header ]) {
      const { listDependency, formula } = yearlySumFormulas[ item.header ];
      const values = extractValues(listDependency, data);
      const year = formula(values);


      return {
        ...item,
        year: isFinite(year) ? Number(year.toFixed(3)) : 0
      };
    }

    let year = validKeys.reduce((acc, key) => {
      if (item[ key ]) {
        acc += Number(item[ key ]);
      }
      return acc;
    }, 0);

    if (!isFinite(year)) {
      year = 0;
    }

    return {
      ...item,
      year: Number(year.toFixed(3)),
    };
  });
};

export const transformUploadMonthlyData = (data) => {
  if (!data) return [];

  const keysToFlatten = [
    'itemsWasteRemoval',
    'itemsCoalGetting',
    'itemsSRAll',
    'itemsDistance',
    'itemsQuality'
  ];
  const flattenedArray = [];

  keysToFlatten.forEach(key => {
    if (data[ key ]) {
      data[ key ].forEach(item => {
        const transformedItem = { ...item };
        transformedItem.header = transformedItem.itemsName;
        transformedItem.year = transformedItem.total;
        delete transformedItem.itemsName;
        delete transformedItem.total;
        flattenedArray.push(transformedItem);
      });
    }
  });

  return flattenedArray;
};

export const mapDataMonthly = (data) => {
  if (!data) return defaultMonthlyData;

  const mergedMonthlyData = defaultMonthlyData.map(item => {
    const header = item?.header;

    const matchingDataItem = data.find(dataItem =>
      (dataItem?.header === ALIAS[ header ] || dataItem?.header === header)
    );

    if (matchingDataItem) {
      return {
        ...item,
        Jan: matchingDataItem.Jan || matchingDataItem.jan,
        Feb: matchingDataItem.Feb || matchingDataItem.feb,
        Mar: matchingDataItem.Mar || matchingDataItem.mar,
        Apr: matchingDataItem.Apr || matchingDataItem.apr,
        May: matchingDataItem.May || matchingDataItem.may,
        Jun: matchingDataItem.Jun || matchingDataItem.jun,
        Jul: matchingDataItem.Jul || matchingDataItem.jul,
        Aug: matchingDataItem.Aug || matchingDataItem.aug,
        Sep: matchingDataItem.Sep || matchingDataItem.sep,
        Oct: matchingDataItem.Oct || matchingDataItem.oct,
        Nov: matchingDataItem.Nov || matchingDataItem.nov,
        Dec: matchingDataItem.Dec || matchingDataItem.dec,
        year: matchingDataItem.year
      };
    }

    return item;
  });

  const defaultMonthlyPlan = mergedMonthlyData;
  const transformedMonthlyData = mergedMonthlyData.map((item, index, array) => {
    const isHeaderInSumList = sumList[ item?.header ];
    if (isHeaderInSumList) {
      const { listDependency, formula } = isHeaderInSumList;
      const getData = arrayToObject(filterDataByHeaders(defaultMonthlyPlan, listDependency));

      const getDataMonth = LIST_MONTHS.reduce((acc, month) => {
        acc[ month ] = formula(filterDataByKey(getData, month));
        return acc;
      }, {});

      const final = {
        ...item,
        ...getDataMonth,
        year: sumValues(getDataMonth)
      };

      defaultMonthlyPlan[ index ] = final;
      return final;
    } else {
      return item;
    }
  });

  const calculatedYear = sumYear(transformedMonthlyData);

  return calculatedYear;
};


export const defaultData = [
  {
    header: 'OB Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Soil Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Mud Original Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Total Waste Inpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'OB Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Soil Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Mud Original Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Total Waste Outpit',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'OB Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Soil Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Mud Original Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Total Waste Project',
    unit: 'BCM',
    code: 'waste',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Total Waste',
    unit: 'BCM',
    code: 'waste',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Opening Inventory',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Coal Expose',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Coal to Mine',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Cap Unit Coal',
    unit: 'tonnes',
    code: 'coal',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Coal Getting',
    unit: 'tonnes',
    code: 'coal',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Closing Inventory',
    unit: 'tonnes',
    code: 'sr',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'SR Expose',
    unit: 'BCM/ton',
    code: 'sr',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'SR Inpit (CG)',
    unit: 'BCM/ton',
    code: 'sr',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'SR All',
    unit: 'BCM/ton',
    code: 'sr',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Coal',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance OB Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Soil Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Mud Original Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Waste Inpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance OB Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Soil Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Mud Original Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Waste Outpit',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance OB Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Soil Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Mud Original Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Waste Project',
    unit: 'meter',
    code: 'distance',
    isHeader: false,
    isSubHeader: true,
    disabledRow: false,
    year: null,
  },
  {
    header: 'Distance Waste All',
    unit: 'meter',
    code: 'distance',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'ASH',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'CV_ADB',
    unit: 'kcal/kg',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'CV_GAR',
    unit: 'kcal/kg',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'FC',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'IM',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'RD_IS',
    unit: 'ton/bcm',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'TM',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'TS',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'VM',
    unit: '%',
    isHeader: false,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  },
  {
    code: 'quality',
    header: 'Quality',
    unit: '',
    isHeader: true,
    isSubHeader: false,
    disabledRow: false,
    year: null,
  }
];


export const addDatesToData = (data, month, year) => {
  // console.log('😒 | month, year:', month, year);
  const dates = {};
  const daysInMonth = moment(`${year}-${month}`, "YYYY-MM").daysInMonth();

  for (let day = 1; day <= daysInMonth; day++) {
    dates[ `${day}/${month}/${year}` ] = 0;
  }

  return data.map(item => ({ ...item, ...dates }));
};

export const defaultDailyData = (month, year) => addDatesToData(defaultData, month, year);
