import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { cloneDeep, constant, isInteger, sum, sumBy } from 'lodash';
import moment from 'moment';
import { apiUrls, deleteRequest, get, patch, post, put } from 'utils/request';
import { DATASOURCE_VALUE, getUsername, calculateShifRitaseAndBcm, addDataChartContractor, addDataChartPit } from 'utils/helper';

export const initialState = {
  wasteRemoval: {
    loading: false,
    records: [],
    totalRecords: "",
    error: "",
    options: {
      pit: [],
      contractor: [],
    }
  },
  wasteRemovalDetail: {
    rainSlippery: {
      id: null,
      forecastTotalRainSlipperyList: [],
      actualTotalRainSlipperyList: [],
    },
    production: {
      id: null,
      data: [],
      documentList: [],
      totalMaterial: [],
      allTotalMaterial: 0,
      getLatest: false,
    },
    maintenanceUnit: {
      id: null,
      maintenanceDataList: []
    },
    userName: "",
    pit: "",
    shift: null,
    reportDate: null,
  },

  getDetailData: false,

  documentList: [],
  payloadLatest: [],
  versionList: [],
  slipperyDurationPayload: 0,

  wasteRemovalCreate: {
    loading: false,
    records: [],
    error: ""
  },

  activeIdDetail: 'create-report',
  loading: false,
  error: "",
  response: "",
  bcmSummary: {
    loading: false,
    records: []
  },
  bcmSummaryDetail: {
    loading: false,
    records: {}
  },
  bcmSummaryDetailBar: {
    loading: false,
    records: []
  },
};

export const getPitOptions = createAsyncThunk('wasteRemoval/get-pit-options', async (params) => {
  const response = await post(`${apiUrls.masterData}/contractor-locations/filter`, {
    "columns": [
      {
        "data": "id",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "location.name",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "contractor.name",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "bibNotes",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "entityStatus",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": "",
        "searchable": true
      },
      {
        "data": "dataStatus",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": 3,
        "searchable": true
      },
      {
        "data": "location.area.name",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchable": true
      },
      {
        "data": "contractor.code",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchable": true
      }
    ],
    "draw": 1,
    "length": 999999,
    "search": {
      "regex": false,
      "value": ""
    },
    "start": 0
  });
  return response?.data;
});

export const getContractorOptions = createAsyncThunk('wasteRemoval/get-loading-point-options', async (params) => {
  const response = await get(`${apiUrls.masterData}/areas/names/Pit/contractors`);
  return response?.data;
});

export const getWasteRemoval = createAsyncThunk('wasteRemoval', async (params) => {
  const response = await post(`${apiUrls.wasteRemoval}/filter`, {
    "columns": [
      {
        "data": "reportId",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.reportId || '',
        "searchable": true
      },
      {
        "data": "createdAt",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": params.date || "",
        "searchable": true
      },
      {
        "data": "shift",
        "orderable": true,
        "search": {
          "regex": false,
          "value": ""
        },
        "searchValue": params?.shift || '',
        "searchable": true
      },
      {
        "data": "version",
        "orderable": true,
        "search": {
          "regex": false,
          "value": "string"
        },
        "searchValue": params?.version || '',
        "searchable": true
      },
      {
        "data": "pit",
        "orderable": true,
        "search": {
          "regex": params?.pit ? true : false,
          "value": ""
        },
        "searchValue": params?.pit || '',
        "searchable": true
      },
      {
        "data": "contractor",
        "orderable": true,
        "search": {
          "regex": params?.contractor ? true : false,
          "value": ""
        },
        "searchValue": params?.contractor || '',
        "searchable": true
      },
      {
        "data": "dataStatus",
        "orderable": true,
        "search": {
          "regex": params?.dataStatus === DATASOURCE_VALUE.waitingApproval || params?.dataStatus >= 20 ? true : false,
          "value": ""
        },
        "searchValue": params?.dataStatus || '',
        "searchable": true
      },
      {
        "data": "reportDate",
        "orderable": true,
        "search": {
          "regex": true,
          "value": ""
        },
        "searchValue": params.reportDate || "",
        "searchable": true
      }
    ],
    "search": {
      "regex": false,
      "value": params.query || ""
    },
    "draw": params?.draw || 1,
    "length": params?.length || 50,
  });
  return response?.data;
});

export const getVersionList = createAsyncThunk('wasteRemoval/get-version', async (params) => {
  const reportId = params;
  const response = await get(`${apiUrls.wasteRemoval}/${reportId}/versions`);
  return response?.data;
});

export const getReportByVersion = createAsyncThunk('wasteRemoval/getByVersion', async (params) => {
  const { reportId, version } = params;
  const response = await get(`${apiUrls.wasteRemoval}/${reportId}/versions/${version}`);
  return response?.data;
})

export const setActivatedVersion = createAsyncThunk('wasteRemoval/setActiveVersion', async (params) => {
  const { reportId, version } = params;
  const response = await patch(`${apiUrls.wasteRemoval}/${reportId}/versions/${version}`);
  return response?.data;
});

export const getLatestPayload = createAsyncThunk('default-payload-waste/get-latest', async (params) => {
  const { contractorCode } = params;
  const response = await get(`${apiUrls.payloadWasteRemoval}/latest-detail?contractorCode=${contractorCode}`);
  return response?.data;
});

export const getDurationWaste = createAsyncThunk('wasteRemoval/get-duration', async (params) => {
  const response = await get(`${apiUrls.wasteRemoval}/slippery-waste/duration`);
  return response?.data;
});

export const addWasteRemoval = createAsyncThunk('wasteRemoval/add', async (params) => {
  const response = await post(`${apiUrls.wasteRemoval}/save?userName=${getUsername()}`, {
    "contractor": params.contractor,
    "pit": params.pit,
    "shift": params.shift,
    "userName": params.userName
  });
  return response?.data;
});

export const setVersionWasteRemoval = createAsyncThunk('wasteRemoval/set-version', async (params) => {
  const response = await patch(`${apiUrls.wasteRemoval}/${params.id}/versions/${params.version}?userName=${getUsername()}`);
  return response?.data;
});

export const saveWasteRemoval = createAsyncThunk('wasteRemoval/save', async (params) => {
  const newActualTotalRainSlipperyList = params.actualTotalRainSlipperyList.map(dt => {
    return {
      ch: typeof dt.ch === 'string' ? parseFloat(dt.ch.replace(",", ".")) : dt.ch,
      endRain: dt.endRain,
      endSlippery: dt.endSlippery,
      startRain: dt.startRain,
      startSlippery: dt.startSlippery,
      type: dt.type,
      durationRain: typeof dt.durationRain === 'string' ? parseFloat(dt.durationRain.replace(",", ".")) : dt.durationRain,
      durationSlippery: typeof dt.durationSlippery === 'string' ? parseFloat(dt.durationSlippery.replace(",", ".")) : dt.durationSlippery,
    }
  })

  const response = await put(`${apiUrls.wasteRemoval}/${params.id}/save?userName=${getUsername()}`, {
    "contractor": params.contractor,
    "rainSlippery": {
      "id": params.slippery,
      "forecastTotalRainSlipperyList": params.forecastTotalRainSlipperyList || [],
      "actualTotalRainSlipperyList": newActualTotalRainSlipperyList || []
    },
    "pit": params.pit,
    "production": {
      "id": params.production,
      "data": params.productionData || [],
      "documentList": []
    },
    "maintenanceUnit": {
      "id": params.maintenanceUnit,
      "maintenanceDataList": params.maintenanceDataList || []
    },
    "shift": params.shift,
    "reportDate": params.reportDate,
    "updatedAt": new Date()
  });
  return response?.data;
});

export const submitWasteRemoval = createAsyncThunk('wasteRemoval/submit', async (payload) => {
  const userName = getUsername();
  const url = !isInteger(payload.id) ? `${apiUrls.wasteRemoval}/${payload.action}?userName=${userName}` :
    `${apiUrls.wasteRemoval}/${payload.id}/${payload.action}?userName=${userName}`

  let object = {}

  if (!isInteger(payload.id)) {
    const newActualTotalRainSlipperyList = payload.rainSlippery.actualTotalRainSlipperyList.map(dt => {
      return {
        ch: typeof dt.ch === 'string' ? parseFloat(dt.ch.replace(",", ".")) : dt.ch,
        endRain: dt.endRain,
        endSlippery: dt.endSlippery,
        startRain: dt.startRain,
        startSlippery: dt.startSlippery,
        type: dt.type,
        durationRain: typeof dt.durationRain === 'string' ? parseFloat(dt.durationRain.replace(",", ".")) : dt.durationRain,
        durationSlippery: typeof dt.durationSlippery === 'string' ? parseFloat(dt.durationSlippery.replace(",", ".")) : dt.durationSlippery,
      }
    })

    const newRainSlippery = {
      actualTotalRainSlipperyList: newActualTotalRainSlipperyList,
      forecastTotalRainSlipperyList: payload.rainSlippery.forecastTotalRainSlipperyList
    }

    object = {
      contractor: payload.contractor,
      pit: payload.pit,
      shift: payload.shift,
      rainSlippery: newRainSlippery,
      production: payload.production,
      maintenanceUnit: payload.maintenanceUnit,
      reportDate: payload.reportDate,
    }
  }
  else {
    if (payload.isAdmin) {
      object = {
        notes: payload.notes
      }
    }
    else {
      object = {
        contractor: payload.contractor,
        id: payload.id,
        pit: payload.pit,
        shift: payload.shift,
        rainSlippery: payload.rainSlippery,
        production: payload.production,
        maintenanceUnit: payload.maintenanceUnit,
        reportDate: payload.reportDate,
      }
    }
  }

  let response;

  if (payload.method == "put") response = await put(url, object);
  else if (payload.method == "post") response = await post(url, object);

  return response?.data;
});

export const deleteWasteRemoval = createAsyncThunk('wasteRemoval/delete', async (params) => {
  const response = await deleteRequest(`${apiUrls.wasteRemoval}/${params}`);
  return response?.data;
});

export const getDetailWasteRemoval = createAsyncThunk('wasteRemoval/detail', async (params) => {
  const { id } = params;
  const response = await get(`${apiUrls.wasteRemoval}/${id}`);
  return response?.data;
});

export const getDetailWasteRemovalUpdateAt = createAsyncThunk('wasteRemoval/detail-update', async (params) => {
  const { id } = params;
  const response = await get(`${apiUrls.wasteRemoval}/${id}`);
  return response?.data;
});

export const getLatestProductionByContractorName = createAsyncThunk('wasteRemoval/production/latest', async (params) => {
  const { contractorName } = params;
  const response = await get(`${apiUrls.wasteRemoval}/productions/contractor-name/${contractorName}`);
  if (response?.data?.production?.data.length > 0) {
    const dtParents = response.data.production.data.map(dt => {
      return {
        ...dt,
        rowNumber: dt.rowNumber,
        subRows: dt.subRows.map(sr => {
          return {
            ...sr,
            fleet: dt.fleet,
            rowNumber: dt.rowNumber
          }
        })
      }
    });
    return { production: dtParents, pit: response?.data?.pit };
  }
  return { production: [], pit: {} };
})

export const postDataDocumentLIst = createAsyncThunk('wasteRemoval/create', async (params) => {
  const response = await post(`${apiUrls.wasteRemoval}/productions${params.id}/files`);
  return response?.data
})

export const getDetailWasteByVersion = createAsyncThunk('wasteRemoval/get-detail-by-version', async (params) => {
  const { reportId, version } = params;
  const response = await get(
    `${apiUrls.wasteRemoval}/${reportId}/versions/${version}`
  );
  return response?.data;
});

export const getContractor = createAsyncThunk('get-contractor', async (params) => {
  const response = await post(`${apiUrls.masterData}/contractors/filter`, {
    "columns": [
      {
        data: 'name',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params.name,
        searchable: true,
      },
    ],
    "search": {
      "regex": false,
      "value": params.query || ""
    },
    "draw": 1,
    "length": 1,
  });
  return response?.data;
});

export const getPit = createAsyncThunk('get-pit', async (params) => {
  const response = await post(`${apiUrls.masterData}/locations/filter`, {
    "columns": [
      {
        data: 'area.name',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: 'Pit',
        searchable: true,
      },
      {
        data: 'name',
        orderable: true,
        search: {
          regex: false,
          value: '',
        },
        searchValue: params.name,
        searchable: true,
      },
    ],
    "search": {
      "regex": false,
      "value": params.query || ""
    },
    "draw": 1,
    "length": 1,
  });
  return response?.data;
});

export const intervensiWasteRemoval = createAsyncThunk(
  'wasteRemoval/intervensi',
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.wasteRemoval}/intervensi/user-role?moduleName=WasteRemoval`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

export const getWasteBcm = createAsyncThunk('wasteRemoval/bcm-summary', async (params) => {
  const { contractor = contractor = params.contractor !== "" ? params.contractor : "All", month, pit = 'All', year } = params

  const response = await post(
    `${apiUrls.wasteRemoval}/bcm-summary?contractor=${contractor}&month=${month}&pit=${pit}&year=${year}`,);
  return response?.data;
});

const wasteRemovalSlice = createSlice({
  name: 'WASTE_REMOVAL',
  initialState,
  reducers: {
    getWasteRemovalDetail: (state, action) => {
      state.wasteRemovalDetail = action.payload;
    },

    activeIdDetail: (state, action) => {
      state.activeIdDetail = action.payload;
    },

    createWasteRemoval: (state, action) => {
      state.wasteRemovalCreate.records = action.payload;
    },

    clearWasteRemovalDetail: (state, action) => {
      // state.wasteRemovalDetail = {
      //   rainSlippery: {
      //     forecastTotalRainSlipperyList: [],
      //     actualTotalRainSlipperyList: []
      //   },
      //   production: {
      //     data: [],
      //     documentList: []
      //   },
      //   maintenanceUnit: {
      //     maintenanceDataList: []
      //   },
      //   userName: ""
      // };
      // state.documentList = []

      state.wasteRemovalDetail = {
        rainSlippery: {
          id: null,
          forecastTotalRainSlipperyList: [],
          actualTotalRainSlipperyList: [],
        },
        production: {
          id: null,
          data: [],
          documentList: [],
          totalMaterial: [],
          allTotalMaterial: 0,
          getLatest: false,
        },
        maintenanceUnit: {
          id: null,
          maintenanceDataList: []
        },
        userName: "",
        pit: "",
        shift: null,
        reportDate: null,
      }

      state.getDetailData = false
      state.documentList = []
    },

    /**
     * * ADD ACTUAL TOTAL RAIN *
     * Todo: to add actual total rain table on waste removal slippery page
     * @param action
     */
    addActualTotalRain: (state, action) => {
      state.wasteRemovalDetail['rainSlippery']['actualTotalRainSlipperyList'].push(action.payload);
    },

    /**
     * * ADD DATA TABLE PRODUCTION *
     * Todo: to add table on waste removal production page
     * @param action
     */
    addDataTableProduction: (state, action) => {
      state.wasteRemovalDetail?.production?.data.push(action.payload);
    },

    /**
     * * ADD DATA TABLE PRODUCTION *
     * Todo: to add table on waste removal production page
     * @param action
     */
    addUploadDataTableProduction: (state, action) => {
      // console.log("production", current(state.wasteRemovalDetail.production.data))
      // console.log("payload", action.payload)
      const payload = action.payload
      const finalData = [];
      const dtProduction = [];
      const dtParentProduction = payload.sheetProduction.filter(el => el.parentRow === true);
      const dtSrProduction = payload.sheetProduction.filter(el => el.parentRow === false);
      let renewFleet = 0;
      dtParentProduction.forEach(dt => {
        renewFleet += 1;
        const listSubRow = dtSrProduction.filter(el => el.fleet === dt.fleet)
        const tempData = {
          ...dt,
          rowNumber: dt.fleet,
          fleet: renewFleet,
          unit: payload.equipmentDetailList.find(el => el.equipmentClass === dt.unit) || "",
          id: payload.listId.find(el => el.equipmentDetailId === dt.equipmentdetailid) || "",
          status: { job: "Production" },
          bcm: 0,
          // ewh: parseFloat(dt.ewh).toFixed(2),
          subRows: (listSubRow?.length > 0) ? dtSrProduction.filter(el => el.fleet === dt.fleet).map(sr => {
            return {
              ...sr,
              rowNumber: dt.fleet,
              fleet: renewFleet,
              unit: payload.equipmentDetailSubList.find(el => el.equipmentClass === sr.unit) || "",
              id: "",
              dumpingPoint: payload.dumpingPointLocation.find(el => el.name === sr.dumpingpoinsublocationt) || "",
              loadingPoint: payload.listDumpingLocation.find(el => el.name === sr.dumpingpoinlocationt) || "",
              loadingPointSubLocation: payload.loadingPointLocation.find(el => el.name === sr.loadingpointsublocation) || "",
              material: payload.materialList.find(el => el.name === sr.materail) || "",
            }
          }) :
            [{
              fleet: renewFleet,
              rowNumber: dt.fleet,
              unit: "",
              id: "",
              status: "",
              material: "",
              location: "",
              ritase: 0,
              bcm: 0,
              productivity: "",
              shif1: 0,
              shif2: 0,
              shif3: 0,
              shif4: 0,
              shif5: 0,
              shif6: 0,
              shif7: 0,
              shif8: 0,
              shif9: 0,
              shif10: 0,
              shif11: 0,
              shif12: 0,
              quantity: 0,
            }]
        }
        dtProduction.push(tempData);
      });

      state.wasteRemovalDetail.production.data = calculateShifRitaseAndBcm(dtProduction);


      const payloadList = current(state.payloadLatest);
      // state.wasteRemovalDetail.production.data.map((row, index) => {
      //   let counterHeader = 0, shiftRitase, counterHeaderBcm;
      //   let totalRitase = 0;

      //   row.subRows.map((sub, idx) => {
      //     const cloneShift = cloneDeep(sub);
      //     delete cloneShift['ritase'];
      //     delete cloneShift['bcm'];
      //     delete cloneShift['payloadValue'];
      //     delete cloneShift['fleet'];
      //     delete cloneShift['rowNumber'];
      //     delete cloneShift['quantity'];
      //     delete cloneShift['ewh'];
      //     delete cloneShift['bcmh'];
      //     delete cloneShift['dumpingpoinlocationt'];
      //     const shift = Object.values(cloneShift);
      //     const shiftFilterNumber = shift.filter(Number);

      //     shiftRitase = sum(shiftFilterNumber);
      //     state.wasteRemovalDetail.production.data[index]['subRows'][idx]['ritase'] = shiftRitase;


      //     payloadList.map((row) => {
      //       if (row.materialId == sub.material?.id && row.materialName == sub.material?.name && row.equipmentId == sub.unit.id && row.locationName == (state.wasteRemovalDetail?.pit?.name || state.wasteRemovalDetail?.pit)) {
      //         const payloadVal = shiftRitase * row.value;
      //         state.wasteRemovalDetail.production.data[index]['subRows'][idx]['payloadValue'] = payloadVal.toFixed(3);
      //       }
      //     })

      //     if (!state.wasteRemovalDetail.production.data[index]['subRows'][idx]['payloadValue']) {
      //       state.wasteRemovalDetail.production.data[index]['subRows'][idx]['bcm'] = 0;
      //     }
      //     else {
      //       state.wasteRemovalDetail.production.data[index]['subRows'][idx]['bcm'] = parseFloat(state.wasteRemovalDetail.production.data[index]['subRows'][idx]['payloadValue']).toFixed(3);
      //     }

      //     totalRitase += shiftRitase ? shiftRitase : 0;


      //   })

      //   state.wasteRemovalDetail.production.data[index]['ritase'] = totalRitase;

      //   const shifArr = { shif1: "", shif2: "", shif3: "", shif4: "", shif5: "", shif6: "", shif7: "", shif8: "", shif9: "", shif10: "", shif11: "", shif12: "" };
      //   Object.keys(shifArr).forEach(key => {
      //     shifArr[key] = sumBy(state.wasteRemovalDetail.production.data[index]['subRows'], key);
      //   });

      //   state.wasteRemovalDetail.production.data[index] = { ...state.wasteRemovalDetail.production.data[index], ...shifArr }

      //   counterHeaderBcm = sumBy(state.wasteRemovalDetail.production.data[index]['subRows'], function (o) { return parseFloat(o.bcm); })
      //   state.wasteRemovalDetail.production.data[index]['bcm'] = parseFloat(counterHeaderBcm).toFixed(3);

      //   // const bcmhVal = (parseFloat(counterHeaderBcm)||0)/(parseFloat(row['ewh'])||0);
      //   // state.wasteRemovalDetail.production.data[index]['bcmh'] = isFinite(bcmhVal) ? (parseFloat(bcmhVal).toFixed(3) || 0) : 0;
      // });

      const totalMaterial = state.wasteRemovalDetail.production.totalMaterial;
      let tableProduction = current(state.wasteRemovalDetail).production.data;


      // calculation bcm and total material
      state.wasteRemovalDetail.production.allTotalMaterial = 0;

      if (tableProduction.length > 0) {
        let tempTotalMaterial = 0;
        tableProduction.map((row, index) => {
          tempTotalMaterial += parseFloat(row.bcm);
        })
        state.wasteRemovalDetail.production.allTotalMaterial = tempTotalMaterial;
      }

      console.log('😒 ♾️ file: reducer.js:764 ♾️ tableProduction:', current(state.wasteRemovalDetail).production.data);
      const tableProductionArr = [];
      tableProduction = tableProduction.map(dt => dt.subRows.map(el => tableProductionArr.push(el)));

      tableProductionArr.map((row, index) => {
        let arr = [];
        let obj = {};
        let newMaterial;
        let isMaterial = false;
        let currentMaterial;

        if (!totalMaterial || totalMaterial.length === 0) {
          obj.materialId = row?.material?.id;
          obj.material = row?.material?.name;
          obj.value = row.bcm ? parseFloat(row.bcm) : 0;
          obj.index = index;
          if (obj.materialId && obj.material) {
            arr.push(obj);
            state.wasteRemovalDetail.production.totalMaterial = arr;
          }

        } else {
          let rowBcmVal = 0;
          currentMaterial = current(totalMaterial);
          currentMaterial.map((val, indexMaterial) => {
            if (val.materialId == row?.material?.id && val.material == row?.material?.name) {
              if (state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value']) {
                if (val.index == index) {
                  rowBcmVal += row.bcm ? parseFloat(row.bcm) : 0;
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = rowBcmVal.toFixed(3);
                } else {
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm) + val.value;
                }
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
              else {
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
            }

            if (!val.material) {
              state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
              state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
              state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              isMaterial = true;
            }
          })

          const currentMaterialAfterUpdate = state.wasteRemovalDetail.production.totalMaterial;
          newMaterial = currentMaterialAfterUpdate.some(item => item.materialId === row.material?.id)

          if (!isMaterial && !newMaterial && row?.material?.name) {
            obj.materialId = row?.material?.id;
            obj.material = row?.material?.name;
            obj.value = row.bcm ? parseFloat(row.bcm) : 0;
            obj.index = index;
            state.wasteRemovalDetail.production.totalMaterial.push(obj);
          }
        }
      });

      // Re-check all material exist in row and remaping all
      const arr = [];
      let allTotalValueMaterial = 0;

      tableProductionArr.map((product, index) => {
        console.log('😒 ♾️ file: reducer.js:826 ♾️ allTotalMaterial:', tableProductionArr);
        let materialObj = {};
        materialObj.value = product.bcm ? parseFloat(product.bcm) : 0;
        materialObj.index = index;
        materialObj.material = product.material?.name;
        materialObj.materialId = product.material?.id;

        if (materialObj.materialId && materialObj.material && !isNaN(product.bcm)) {
          arr.push(materialObj);
          allTotalValueMaterial += parseFloat(product.bcm);
        }
      });

      const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
        prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId].value) : 0) + value).toFixed(3) }
        return prev;
      }, {}));

      state.wasteRemovalDetail.production.totalMaterial = mergeSameMaterial;
      // state.wasteRemovalDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);

    },

    /**
     * * ADD DATA TABLE PRODUCTION *
     * Todo: to add table on waste removal production page
     * @param action
     */
    addDataTableDropdownProduction: (state, action) => {
      const payload = action.payload;
      const currentChangeRowIndex = payload.subRowIndex + 1;
      const lengthSubRow = state.wasteRemovalDetail?.production?.data[payload.tableIndexRow]['subRows'].length - 1;
      if (currentChangeRowIndex != lengthSubRow) {
        state.wasteRemovalDetail?.production?.data[payload.tableIndexRow]['subRows'].push(payload.data);
      }
    },

    /**
     * * ONCHANGE PIT *
     * Todo: to change table on waste removal production page
     * @param action
     */
    onChangePit: (state, action) => {
      const payload = action.payload.value;
      if (state.wasteRemovalDetail.pit !== payload) {
        state.wasteRemovalDetail.production.allTotalMaterial = 0;

        state.wasteRemovalDetail.production.data.map((object, index) => {
          state.wasteRemovalDetail.production.data[index] = { ...object, bcm: 0 };

          const listSubRow = [];
          object.subRows.map((sr, idx) => {
            listSubRow.push({ ...sr, loadingPointSubLocation: "", bcm: 0, payloadValue: 0, })
          })

          state.wasteRemovalDetail.production.data[index].subRows = listSubRow;
        });

        if (state.wasteRemovalDetail?.production?.totalMaterial?.length > 0) {
          state.wasteRemovalDetail.production.totalMaterial.map((dt, idx) => {
            state.wasteRemovalDetail.production.totalMaterial[idx] = { ...dt, value: 0 }
          })
        }

        const payloadList = current(state.payloadLatest);
        state.wasteRemovalDetail.production.data.map((object, index) => {
          object.subRows.map((sr, idx) => {
            if (sr.unit?.id && sr.material?.name) {
              payloadList.map((row) => {
                //toDo: nanti tambahin kondisi cek location apakah sesuai dengan pit
                if (row?.materialId == sr.material?.id && row?.materialName == sr.material?.name && row?.equipmentId == sr.unit?.id && row?.locationName == payload) {
                  const ritaseVal = state.wasteRemovalDetail.production.data[index]['subRows'][idx]['ritase']
                  const bcmVal = ritaseVal * row.value
                  state.wasteRemovalDetail.production.data[index]['subRows'][idx]['bcm'] = bcmVal.toFixed(3);
                  state.wasteRemovalDetail.production.data[index]['subRows'][idx]['payloadValue'] = bcmVal.toFixed(3);
                }
              })
            }
          })

          let sumBcmSubRow = sumBy(state.wasteRemovalDetail.production.data[index]['subRows'], 'bcm');
          sumBcmSubRow = sumBcmSubRow ? sumBcmSubRow : 0;
          state.wasteRemovalDetail.production.data[index]['bcm'] = parseFloat(sumBcmSubRow).toFixed(3);

          const bcmhVal = (parseFloat(sumBcmSubRow)||0)/(parseFloat(object['ewh'])||0);
          state.wasteRemovalDetail.production.data[index]['bcmh'] = isFinite(bcmhVal) ? (parseFloat(bcmhVal).toFixed(3) || 0) : 0;
        });

        let totalMaterial = state.wasteRemovalDetail.production.totalMaterial;
        let tableProduction = current(state.wasteRemovalDetail.production.data);
        const tableProductionArr = [];
        tableProduction = tableProduction.map(dt => dt.subRows.map(el => tableProductionArr.push(el)));

        tableProductionArr.map((row, index) => {
          let arr = [];
          let obj = {};
          let newMaterial;
          let isMaterial = false;
          let currentMaterial;

          if (!totalMaterial || totalMaterial.length === 0) {
            obj.materialId = row?.material?.id;
            obj.material = row?.material?.name;
            obj.value = row.bcm ? row.bcm : 0;
            obj.index = index;
            if (obj.materialId && obj.material) {
              arr.push(obj);
              state.wasteRemovalDetail.production.totalMaterial = arr;
            }

          }
          else {
            let rowBcmVal = 0;
            currentMaterial = current(totalMaterial);
            currentMaterial.map((val, indexMaterial) => {
              if (val.materialId == row?.material?.id && val.material == row?.material?.name) {
                if (state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value']) {
                  if (val.index == index) {
                    rowBcmVal += row.bcm ? parseFloat(row.bcm) : 0;
                    state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = rowBcmVal.toFixed(3);
                  } else {
                    state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = row.bcm + val.value;
                  }
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
                }
                else {
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = row.bcm;
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
                }
              }

              if (!val.material) {
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = row.bcm;
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
                isMaterial = true;
              }
            })

            const currentMaterialAfterUpdate = state.wasteRemovalDetail.production.totalMaterial;
            newMaterial = currentMaterialAfterUpdate.some(item => item.materialId === row.material?.id)

            if (!isMaterial && !newMaterial && row?.material?.name) {
              obj.materialId = row?.material?.id;
              obj.material = row?.material?.name;
              obj.value = row.bcm ? row.bcm : 0;
              obj.index = index;
              state.wasteRemovalDetail.production.totalMaterial.push(obj);
            }
          }
        });

        // Re-check all material exist in row and remaping all
        const productionData = current(state.wasteRemovalDetail.production.data);
        const arr = [];
        let allTotalValueMaterial = 0;

        tableProductionArr.map((product, index) => {
          console.log('😒 ♾️ file: reducer.js:985 ♾️ allTotalMaterial:', tableProductionArr);
          let materialObj = {};
          materialObj.value = product.bcm ? parseFloat(product.bcm) : 0;
          materialObj.index = index;
          materialObj.material = product.material?.name;
          materialObj.materialId = product.material?.id;

          if (materialObj.materialId && materialObj.material && !isNaN(product.bcm)) {
            arr.push(materialObj);
            allTotalValueMaterial += parseFloat(product.bcm);
          }
        });

        const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
          prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId].value) : 0) + value).toFixed(3) }
          return prev;
        }, {}));

        state.wasteRemovalDetail.production.totalMaterial = mergeSameMaterial;
        state.wasteRemovalDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);

      }
    },

    /**
     * * ONCHANGE TABLE PRODUCTION *
     * Todo: to change table on waste removal production page
     * @param action
     */
    onChangeTableProductionWaste: (state, action) => {
      const payload = action.payload;
      let checkValue = payload.value,
        counterHeader = 0,
        counterHeaderRitase,
        counterHeaderBcm,
        shiftRitase;

      if (isInteger(parseInt(payload.value)) && payload.tableRowName !== "notes" && payload.tableRowName !== "ewh") checkValue = parseInt(payload.value);
      /**
       * Todo: to parsing new value into row column
       */
      if (payload.canExpand) {
        state.wasteRemovalDetail.production.data
        [payload.tableIndexRow]
        [payload.tableRowName] = checkValue;

        if (payload.tableRowName === "unit") {
          state.wasteRemovalDetail.production.data
          [payload.tableIndexRow]
          ["id"] = "";
        }
      }
      if (!payload.canExpand) {
        state.wasteRemovalDetail.production.data
        [payload.tableIndexRow]
        ['subRows']
        [payload.subRowIndex]
        [payload.tableRowName] = checkValue;


        state.wasteRemovalDetail?.production?.data[payload.tableIndexRow]['subRows'].push({});
        state.wasteRemovalDetail?.production?.data[payload.tableIndexRow]['subRows'].pop();

        if (payload.tableRowName === "unit") {
          state.wasteRemovalDetail.production.data
          [payload.tableIndexRow]
          ['subRows']
          [payload.subRowIndex]
          ["id"] = "";

          state.wasteRemovalDetail.production.data
          [payload.tableIndexRow]
          ['subRows']
          [payload.subRowIndex]
          ['loadingPoint'] = state.wasteRemovalDetail.production.data[payload.tableIndexRow]['loadingPoint'];
        }
        else if (payload.tableRowName === "loadingPoint") {
          state.wasteRemovalDetail.production.data
          [payload.tableIndexRow]
          ['subRows']
          [payload.subRowIndex]
          ["dumpingPoint"] = "";
        }

        if (payload.tableRowName === "loadingPointSubLocation") {
          const dataSubrow = current(state.wasteRemovalDetail.production.data
          [payload.tableIndexRow]
          ['subRows']);

          dataSubrow.map((dt, idx) => {
            state.wasteRemovalDetail.production.data
            [payload.tableIndexRow]
            ['subRows']
            [idx]
            ["loadingPointSubLocation"] = checkValue;
          })
        }
      }

      const currentMaterial = state.wasteRemovalDetail.production.data
      [payload.tableIndexRow]
      ['subRows']?.[payload.subRowIndex]?.['material'];

      const currentUnit = state.wasteRemovalDetail.production.data
      [payload.tableIndexRow]
      ['subRows']?.[payload.subRowIndex]?.['unit'];

      const currentSubRows = current(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows']);
      const equipmentId = currentUnit?.id;
      const materialName = currentMaterial?.name;
      const materialId = currentMaterial?.id;

      const payloadList = current(state.payloadLatest);
      if (payload.tableRowName === "unit" || payload.tableRowName === "material") {
        if (currentUnit && currentMaterial) {

          state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcm'] = 0;

          if (!payload.canExpand) {
            state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = 0;
            payloadList.map((row) => {
              if (row?.materialId == materialId && row.materialName == materialName && row.equipmentId == equipmentId && row.locationName == payload.locPit) {
                const ritaseVal = state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['ritase']
                const bcmVal = ritaseVal * row.value
                state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = bcmVal.toFixed(3);
                state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue'] = parseFloat(bcmVal.toFixed(3));
              }
            })

            let rsCounterHeaderBcm = sumBy(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'], function (o) { return parseFloat(o.bcm); })
            rsCounterHeaderBcm = rsCounterHeaderBcm ? rsCounterHeaderBcm : 0;
            state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcm'] = parseFloat(rsCounterHeaderBcm).toFixed(3);
          }
        }
      }

      let tableProduction = current(state.wasteRemovalDetail.production.data);
      let totalMaterial = state.wasteRemovalDetail.production.totalMaterial;

      if (isInteger(checkValue) && equipmentId && payload.tableRowName !== "notes" && payload.tableRowName !== "quantity" && payload.tableRowName !== "ewh") {
        tableProduction.map((row, index) => {
          row.subRows.map((sub, idx) => {
            if (sub[payload.tableRowName] && isInteger(parseInt(sub[payload.tableRowName])) && index === payload.tableIndexRow) {
              counterHeader += parseInt(sub[payload.tableRowName]);
            }
            if (idx === payload.subRowIndex && index === payload.tableIndexRow) {
              const cloneShift = cloneDeep(sub);
              delete cloneShift['ritase'];
              delete cloneShift['bcm'];
              delete cloneShift['payloadValue'];
              delete cloneShift['fleet'];
              delete cloneShift['rowNumber'];
              delete cloneShift['quantity'];
              delete cloneShift['ewh'];
              delete cloneShift['bcmh'];
              const shift = Object.values(cloneShift);
              const shiftFilterNumber = shift.filter(Number);

              shiftRitase = sum(shiftFilterNumber);
            }
          })
        });

        const currentMaterial = state.wasteRemovalDetail.production.data
        [payload.tableIndexRow]
        ['subRows']
        [payload.subRowIndex]
        ['material'];

        const currentUnit = state.wasteRemovalDetail.production.data
        [payload.tableIndexRow]
        ['subRows']
        [payload.subRowIndex]
        ['unit'];

        if (currentMaterial) {
          const equipmentId = currentUnit?.id;
          const materialName = current(currentMaterial).name;
          const materialId = current(currentMaterial).id;
          const payloadList = current(state.payloadLatest);

          if (payload.locPit && payload.locPit !== '') {
            payloadList.map((row) => {
              if (row.materialId == materialId && row.materialName == materialName && row.equipmentId == equipmentId && row.locationName == payload.locPit) {
                const payloadVal = shiftRitase * row.value;
                state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue'] = payloadVal.toFixed(3);
              }
            })
          }

        }

        state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['ritase'] = shiftRitase;

        if (!state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue']) {
          state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = 0;
        }
        else {
          state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['bcm'] = parseFloat(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'][payload.subRowIndex]['payloadValue']).toFixed(3);
        }

        state.wasteRemovalDetail.production.data[payload.tableIndexRow][payload.tableRowName] = counterHeader;
        const currentSubRow = current(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows']);
        let totalRitase = 0;
        currentSubRow.map((val) => {
          totalRitase += val.ritase ? val.ritase : 0;
        })
        state.wasteRemovalDetail.production.data[payload.tableIndexRow]['ritase'] = totalRitase;

        counterHeaderBcm = sumBy(current(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows']), function (o) { return parseFloat(o.bcm); })
        state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcm'] = parseFloat(counterHeaderBcm).toFixed(3);
      }

      tableProduction = current(state.wasteRemovalDetail.production.data);
      const tableProductionArr = [];
      tableProduction = tableProduction.map(dt => dt.subRows.map(el => tableProductionArr.push(el)));

      tableProductionArr.map((row, index) => {
        let arr = [];
        let obj = {};
        let newMaterial;
        let isMaterial = false;
        let currentMaterial;

        if (!totalMaterial || totalMaterial.length == 0) {
          obj.materialId = row?.material?.id;
          obj.material = row?.material?.name;
          obj.value = row.bcm ? parseFloat(row.bcm) : 0;
          obj.index = index;
          if (obj.materialId && obj.material) {
            arr.push(obj);
            state.wasteRemovalDetail.production.totalMaterial = arr;
          }

        }
        else {
          let rowBcmVal = 0;
          currentMaterial = current(totalMaterial);
          currentMaterial.map((val, indexMaterial) => {
            if (val.materialId == row?.material?.id && val.material == row?.material?.name) {
              if (state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value']) {
                if (val.index == index) {
                  rowBcmVal += row.bcm ? parseFloat(row.bcm) : 0;
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = rowBcmVal;
                } else {
                  state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm) + val.value;
                }
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
              else {
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
                state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              }
            }

            if (!val.material) {
              state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['value'] = parseFloat(row.bcm);
              state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['material'] = row?.material?.name;
              state.wasteRemovalDetail.production.totalMaterial[indexMaterial]['materialId'] = row?.material?.id;
              isMaterial = true;
            }
          })

          const currentMaterialAfterUpdate = state.wasteRemovalDetail.production.totalMaterial;
          newMaterial = currentMaterialAfterUpdate.some(item => item.materialId === row.material?.id)

          if (!isMaterial && !newMaterial && row?.material?.name) {
            obj.materialId = row?.material?.id;
            obj.material = row?.material?.name;
            obj.value = row.bcm ? parseFloat(row.bcm) : 0;
            obj.index = index;
            state.wasteRemovalDetail.production.totalMaterial.push(obj);
          }
        }
      });

      // Re-check all material exist in row and remaping all
      const productionData = current(state.wasteRemovalDetail.production.data);
      const arr = [];
      let allTotalValueMaterial = 0;

      tableProductionArr.map((product, index) => {
        console.log('😒 ♾️ file: reducer.js:1269 ♾️ allTotalMaterial:', tableProductionArr);
        let materialObj = {};
        materialObj.value = product.bcm ? parseFloat(product.bcm) : 0;
        materialObj.index = index;
        materialObj.material = product.material?.name;
        materialObj.materialId = product.material?.id;

        if (materialObj.materialId && materialObj.material && !isNaN(product.bcm)) {
          arr.push(materialObj);
          allTotalValueMaterial += parseFloat(product.bcm);
        }
      });

      const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
        prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId]?.value) : 0) + value).toFixed(3) }
        return prev;
      }, {}));

      state.wasteRemovalDetail.production.totalMaterial = mergeSameMaterial;
      state.wasteRemovalDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);

      const dataPitToRom = current(state.wasteRemovalDetail).production.data;
      state.wasteRemovalDetail.production.data = calculateShifRitaseAndBcm(dataPitToRom)

      // const bcmhVal = (parseFloat(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcm'])||0) /
      //   (parseFloat(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['ewh']?.toString())||0);
      // state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcmh'] = isFinite(bcmhVal) ? (parseFloat(bcmhVal).toFixed(3) || 0) : 0;
    },

    /**
     * * ON DELETE ROW TABLE PRODUCTION *
     * Todo: to delete page waste removal detail production table
     * @param action
     */
    onDeleteRowTableProduction: (state, action) => {
      let renewFleet = 0;
      let renewProdRn = 0;
      let renewNonProdRn = 0;
      let currentMaterial;
      let totalMaterialData = state.wasteRemovalDetail.production.totalMaterial;
      const payload = action.payload;

      if (payload.canExpand) {
        const dataRow = current(state.wasteRemovalDetail.production.data);
        currentMaterial = current(totalMaterialData);
        const deletedRowIdx = dataRow.findIndex(x => x.fleet === payload.fleet && x.status?.job === payload.status?.job);
        state.wasteRemovalDetail.production.totalMaterial.map((val, idxMaterial) => {
          state.wasteRemovalDetail.production.data[deletedRowIdx]['subRows'].map((dt, idx) => {
            if (val.materialId === dt.material.id && val.material === dt.material.name) {
              if (payload.rowBcm) {
                const currBcm = parseFloat(dt.bcm);
                const materialVal = val.value - currBcm
                state.wasteRemovalDetail.production.totalMaterial[idxMaterial]['value'] = materialVal.toFixed(3);
                const totalMaterialVal = sumBy(state.wasteRemovalDetail.production.totalMaterial, 'value')
                console.log('😒 ♾️ file: reducer.js:1323 ♾️ allTotalMaterial:', current(state.wasteRemovalDetail).production.totalMaterial);
                state.wasteRemovalDetail.production.allTotalMaterial = parseFloat(totalMaterialVal).toFixed(3);
              }
            }
          })
        });

        state.wasteRemovalDetail.production.totalMaterial = state.wasteRemovalDetail.production.totalMaterial.filter((x, index) => x.value !== 0);

        const deletedRow = dataRow.findIndex(x => x.fleet === payload.fleet && x.status?.job === payload.status?.job);
        state.wasteRemovalDetail.production.data = dataRow.filter((x, index) => index !== deletedRow);

        state.wasteRemovalDetail.production.data.map((object, index) => {
          renewFleet += 1;
          if (object.status?.job === "Production") renewProdRn += 1;
          if (object.status?.job === "Non Production") renewNonProdRn += 1;
          state.wasteRemovalDetail.production.data[index] = { ...object, fleet: renewFleet, rowNumber: object.status?.job === "Production" ? renewProdRn : renewNonProdRn };

          const listSubRow = [];
          object.subRows.map((sr, idx) => {
            listSubRow.push({ ...sr, fleet: renewFleet, rowNumber: object.status?.job === "Production" ? renewProdRn : renewNonProdRn })
          })

          state.wasteRemovalDetail.production.data[index].subRows = listSubRow;
        });
      } else {
        const parentRowBcmBeforeDelete = state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcm'];
        const dataSubRow = current(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows']);
        if (dataSubRow.length > 1) {
          state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'] = dataSubRow.filter((x, index) => index != payload.subRowIndex);
        } else {
          const arr = [];
          const obj = { ...dataSubRow[0] };
          arr.push(obj);
          state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'] = arr;
        }

        currentMaterial = current(totalMaterialData);
        currentMaterial.map((val, idxMaterial) => {
          if (val.materialId == payload.rowMaterialId && val.material == payload.rowMaterial) {
            if (payload.rowBcm) {
              const sumSubRowBcm = sumBy(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'], function (o) { return parseFloat(o.bcm); });
              state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcm'] = parseFloat(sumSubRowBcm).toFixed(3);
              const rs1 = (parseFloat(val.value) - parseFloat(parentRowBcmBeforeDelete)).toFixed(3)
              const rs2 = (parseFloat(rs1) + parseFloat(sumSubRowBcm)).toFixed(3)
              state.wasteRemovalDetail.production.totalMaterial[idxMaterial]['value'] = (payload.rowRitase) ? parseFloat(rs2) : val.value - payload.rowBcm;
              const totalMaterialVal = sumBy(current(state.wasteRemovalDetail.production.totalMaterial), 'value')
              console.log('😒 ♾️ file: reducer.js:1370 ♾️ allTotalMaterial:', current(state.wasteRemovalDetail.production.totalMaterial));
              state.wasteRemovalDetail.production.allTotalMaterial = parseFloat(totalMaterialVal).toFixed(3);
            }
          }
        })

        const parentRow = state.wasteRemovalDetail.production.data[payload.tableIndexRow];

        // if (payload.rowRitase != 0) {
        const shifArr = { shif1: "", shif2: "", shif3: "", shif4: "", shif5: "", shif6: "", shif7: "", shif8: "", shif9: "", shif10: "", shif11: "", shif12: "" };
        Object.keys(shifArr).forEach(key => {
          shifArr[key] = sumBy(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'], key);
        });

        const currentRitaseParentRow = sumBy(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['subRows'], 'ritase');
        state.wasteRemovalDetail.production.data[payload.tableIndexRow] = { ...parentRow, ...shifArr, ritase: currentRitaseParentRow }
        // }

        // const bcmhVal = (parseFloat(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcm'])||0) /
        //   (parseFloat(state.wasteRemovalDetail.production.data[payload.tableIndexRow]['ewh'])||0);
        // state.wasteRemovalDetail.production.data[payload.tableIndexRow]['bcmh'] = isFinite(bcmhVal) ? (parseFloat(bcmhVal).toFixed(3) || 0) : 0;
      }
    },

    mappingMaterialDetailPage: (state) => {
      const productionData = current(state.wasteRemovalDetail.production?.data);
      if (productionData.length != undefined) {
        const arr = [];
        let allTotalValueMaterial = 0;

        productionData.map((product, index) => {
          console.log('😒 ♾️ file: reducer.js:1401 ♾️ allTotalMaterial:', productionData);
          product.subRows.map((sr, idx) => {
            let materialObj = {};
            materialObj.value = sr.bcm ? parseFloat(sr.bcm) : 0;
            materialObj.index = idx;
            materialObj.material = sr.material?.name;
            materialObj.materialId = sr.material?.id;

            if (sr.bcm) {
              arr.push(materialObj);
              allTotalValueMaterial += parseFloat(sr.bcm);
            }
          })
        });

        const mergeSameMaterial = Object.values([...arr].reduce((prev, { materialId, material, index, value }) => {
          prev[materialId] = { materialId, material, index, value: parseFloat((prev[materialId] ? parseFloat(prev[materialId].value) : 0) + value).toFixed(3) }
          return prev;
        }, {}));

        state.wasteRemovalDetail.production.totalMaterial = mergeSameMaterial;
        state.wasteRemovalDetail.production.allTotalMaterial = parseFloat(allTotalValueMaterial).toFixed(3);
      }
    },

    /**
     * * ADD DATA TABLE MAINTENANCE *
     * Todo: to add table on waste removal maintenance page
     * @param action
     */
    addDataTableMaintenance: (state, action) => {
      state.wasteRemovalDetail.maintenanceUnit.maintenanceDataList.push(action.payload);
    },

    /**
     * * ONCHANGE TABLE MAINTENANCE *
     * Todo: to add table on waste removal maintenance page
     * @param action
     */
    onChangeTableMaintenanceWaste: (state, action) => {
      const payload = action.payload;
      state.wasteRemovalDetail.maintenanceUnit.maintenanceDataList[payload.tableIndexRow][payload.tableRowName] = payload.value;
    },

    /**
     * * ON DELETE ROW TABLE SLIPPERY *
     * Todo: to delete page waste removal detail slippery table
     * @param action
     */
    onDeleteRowTableMaintenance: (state, action) => {
      const payload = action.payload;
      state.wasteRemovalDetail.maintenanceUnit.maintenanceDataList = state.wasteRemovalDetail.maintenanceUnit.maintenanceDataList.filter((x, index) => index != payload.tableIndexRow);
    },

    /**
     * * ON ADD TABLE SLIPPERY *
     * Todo: to add empty value for editing
     * @param action
     */
    onAddTableSlippery: (state, action) => {
      const payload = action.payload;
      state.wasteRemovalDetail.rainSlippery["actualTotalRainSlipperyList"].push(payload);
    },

    /**
     * * ON CHANGE TABLE DATE SLIPPERY *
     * Todo: to change page waste removal detail slippery table date
     * @param action
     */
    onChangeTableDateSlippery: (state, action) => {
      const payload = action.payload;
      state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow][payload.tableRowName] = payload.value === 'null' ? "" : moment(payload.value).format();
      if (payload.tableRowName === "endRain") {
        state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow]["startSlippery"] = payload.startSlippery !== null ? moment(payload.startSlippery).format() : "";
      }
      if (payload.endSlippery) {
        let rainSlippery = current(state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow])
        if (rainSlippery.startSlippery && rainSlippery.endSlippery) {
          const start = moment(rainSlippery.startSlippery)
          const end = moment(rainSlippery.endSlippery)
          let durationSlippery = moment(end).diff(start, "minutes") / 60
          durationSlippery = parseFloat(durationSlippery.toFixed(2))
          state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationSlippery'] = durationSlippery;
        } else {
          state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationSlippery'] = 0;
        }
      }
      else {
        let rainSlippery = current(state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow])
        if (rainSlippery.startRain && rainSlippery.endRain) {
          const start = moment(rainSlippery.startRain)
          const end = moment(rainSlippery.endRain)
          let durationRain = moment(end).diff(start, "minutes") / 60
          durationRain = parseFloat(durationRain.toFixed(2))
          state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationRain'] = durationRain;
        } else {
          state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationRain'] = 0;
        }
      }

      if (payload.tableRowName === "endRain" && current(state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow]).endSlippery !== "") {
        let rainSlippery = current(state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow])
        const start = moment(rainSlippery.startSlippery)
        const end = moment(rainSlippery.endSlippery)
        const durationSlippery = moment(end).diff(start, "minutes") / 60
        state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow]['durationSlippery'] = durationSlippery.toFixed(2).replace(".", ",");
      }
    },

    /**
     * * ON DELETE ROW TABLE SLIPPERY *
     * Todo: to delete page waste removal detail slippery table
     * @param action
     */
    onDeleteRowTableSlippery: (state, action) => {
      const payload = action.payload;
      const row = current(state.wasteRemovalDetail.rainSlippery[payload.tableName]);
      state.wasteRemovalDetail.rainSlippery[payload.tableName] = row.filter((x, index) => index != payload.tableIndexRow);
    },

    /**
     * * ON CHANGE TABLE DATE SLIPPERY CH*
     * Todo: to change page waste removal detail slippery table date INPUT CH
     * @param action
     */
    onChangeTableDateSlipperyCH: (state, action) => {
      const payload = action.payload;
      state.wasteRemovalDetail.rainSlippery[payload.tableName][payload.tableIndexRow][payload.tableRowName] = payload.value;
    },

    /**
     * * ON CHANGE TABLE DATE SLIPPERY CH*
     * Todo: to change page waste removal detail slippery table date INPUT CH
     * @param action
     */
    onChangePitWatch: (state, action) => {
      const payload = action.payload;
      state.wasteRemovalDetail[payload.name] = payload.value.value;
    },

    onChangeReportDate: (state, action) => {
      const payload = action.payload;
      state.wasteRemovalDetail['reportDate'] = payload.value;
    },

    onChangeDocument: (state, action) => {
      const payload = action.payload;
      // state.documentList.push(payload);
      state.documentList[0] = (payload);
    },

    onResetDocument: (state, action) => {
      state.documentList = [];
    },

    onResetWasteDetail: (state) => {
      state.wasteRemovalDetail = {
        rainSlippery: {
          id: null,
          forecastTotalRainSlipperyList: [],
          actualTotalRainSlipperyList: [],
        },
        production: {
          id: null,
          data: [],
          documentList: [],
          totalMaterial: [],
          allTotalMaterial: 0,
          getLatest: false,
        },
        maintenanceUnit: {
          id: null,
          maintenanceDataList: []
        },
        userName: "",
        pit: "",
        shift: null,
        reportDate: null,
      }

      state.getDetailData = false;

    },
    onResetWasteBCM: (state) => {
      state.bcmSummary = {
        loading: false,
        records: []
      };
      state.bcmSummaryDetailBar = {
        loading: false,
        records: []
      };

    },
    onResetWasteBCMDetail: (state) => {
      state.wasteSummaryDetail = {
        loading: false,
        records: []
      };
    }

  },
  extraReducers: (builder) => {
    builder.addCase(getWasteRemoval.pending, (state, action) => {
      state.loading = true;
      state.wasteRemoval.loading = true;
    });
    builder.addCase(getWasteRemoval.fulfilled, (state, action) => {
      state.wasteRemoval.loading = false;
      state.wasteRemoval.totalRecords = action.payload.recordsTotal;
      state.wasteRemoval.records = action.payload.list;
    });
    builder.addCase(getWasteRemoval.rejected, (state, action) => {
      state.loading = false;
      state.wasteRemoval.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getVersionList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getVersionList.fulfilled, (state, action) => {
      state.versionList = action.payload;
    });
    builder.addCase(getVersionList.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(getLatestPayload.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLatestPayload.fulfilled, (state, action) => {
      const fData = action?.payload.filter(el => el.type === "Waste")
      state.payloadLatest = fData;
    });
    builder.addCase(getLatestPayload.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(saveWasteRemoval.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(saveWasteRemoval.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(saveWasteRemoval.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(submitWasteRemoval.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(submitWasteRemoval.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(submitWasteRemoval.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid get data';
    });

    builder.addCase(addWasteRemoval.pending, (state, action) => {
      state.wasteRemovalCreate.loading = true;
    });
    builder.addCase(addWasteRemoval.fulfilled, (state, action) => {
      state.wasteRemovalCreate.records = action.payload;
    });
    builder.addCase(addWasteRemoval.rejected, (state, action) => {
      state.wasteRemovalCreate.loading = false;
      state.wasteRemovalCreate.error = 'Invalid add data';
    });

    builder.addCase(deleteWasteRemoval.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteWasteRemoval.fulfilled, (state, action) => {
      state.response = action.payload;
    });
    builder.addCase(deleteWasteRemoval.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getDetailWasteRemoval.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDetailWasteRemoval.fulfilled, (state, action) => {
      const data = action.payload;
      const finalData = {
        ...data,
        production: {
          ...data.production,
          pitToRom: data.production.pitToRom,
        }
      }

      state.wasteRemovalDetail = finalData;
      state.getDetailData = true;
    });
    builder.addCase(getDetailWasteRemoval.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getDetailWasteRemovalUpdateAt.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDetailWasteRemovalUpdateAt.fulfilled, (state, action) => {
      state.wasteRemovalDetail.updatedAt = action.payload?.updatedAt;
    });
    builder.addCase(getDetailWasteRemovalUpdateAt.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getLatestProductionByContractorName.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getLatestProductionByContractorName.fulfilled, (state, action) => {
      state.wasteRemovalDetail.production.data = action.payload.production;
      state.wasteRemovalDetail.production.getLatest = true;
      state.wasteRemovalDetail.pit = action.payload.pit;
    });
    builder.addCase(getLatestProductionByContractorName.rejected, (state, action) => {
      state.loading = false;
      state.error = 'Invalid add data';
    });

    builder.addCase(getWasteBcm.pending, (state, action) => {
      state.bcmSummary.loading = true;
    });
    builder.addCase(getWasteBcm.fulfilled, (state, action) => {
      state.bcmSummary.loading = false;
      state.bcmSummary.records = action?.payload;
      state.bcmSummaryDetailBar.records = action?.payload;

      const wasteRemovalData = JSON.parse(JSON.stringify(action.payload)) || [];
      const transformData = addDataChartContractor(addDataChartPit(wasteRemovalData));
      const mergedDataByDay = transformData.reduce((acc, curr) => {
        if (acc[ curr.day ]) {
          acc[ curr.day ].detail = [ ...acc[ curr.day ].detail, ...curr.detailList ];
        } else {
          acc[ curr.day ] = curr;
        }
        return acc;
      }, {});

      state.bcmSummaryDetail.records = mergedDataByDay;
    });
    builder.addCase(getWasteBcm.rejected, (state, action) => {
      state.bcmSummary.loading = false;
    });
    builder.addCase(getPitOptions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getPitOptions.fulfilled, (state, action) => {
      const data = action.payload.list || [];
      const filtered = data.filter((item) => item.entityStatus === 1 && item.location.area.name === "Pit");
      const options = filtered.reduce((unique, item) => {
        if (unique.findIndex(obj => obj.value === item.location.name) === -1) {
          unique.push({ value: item.location.name, label: item.location.name });
        }
        return unique;
      }, []);
      state.wasteRemoval.options.pit = options;
    });
    builder.addCase(getPitOptions.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getContractorOptions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getContractorOptions.fulfilled, (state, action) => {
      const data = action.payload || [];
      const options = data.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      })

      if (options.length > 0) {
        options.unshift({ value: '', label: 'All' });
      }
      state.wasteRemoval.options.contractor = options;
    });
  },
});

export const wasteRemovalSelector = (state) => state.wasteRemoval?.wasteRemoval;
export const wasteRemovalDetailSelector = (state) => state.wasteRemoval?.wasteRemovalDetail;
export const wasteRemovalDocumentSelector = (state) => state.wasteRemoval?.documentList;
export const wasteRemovalVersionList = (state) => state.wasteRemoval?.versionList;
export const wasteRemovalPayloadSelector = (state) => state.payloadLatest;
export const wasteActiveIdDetailSelector = (state) => state.wasteRemoval?.activeIdDetail;
export const wasteRemovalSlipperyDurationSelector = (state) => state.slipperyDurationPayload;
export const wasteRemovalIsGetDetailSelector = (state) => state.wasteRemoval?.getDetailData;
export const wasteRemovalBcm = (state) => state.wasteRemoval?.bcmSummary;
export const wasteRemovalBcmDetail = (state) => state.wasteRemoval?.bcmSummaryDetail;
export const wasteRemovalBcmDetailBar = (state) => state.wasteRemoval?.bcmSummaryDetailBar;

export const {
  getWasteRemovalDetail,
  activeIdDetail,
  addActualTotalRain,
  addDataTableProduction,
  addDataTableDropdownProduction,
  onChangeTableProductionWaste,
  addDataTableMaintenance,
  onChangeTableMaintenanceWaste,
  onDeleteRowTableMaintenance,
  onChangeTableDateSlippery,
  onChangeTableDateSlipperyCH,
  onChangePitWatch,
  onDeleteRowTableSlippery,
  onDeleteRowTableProduction,
  createWasteRemoval,
  clearWasteRemovalDetail,
  mappingMaterialDetailPage,
  onChangeDocument,
  onResetDocument,
  onAddTableSlippery,
  onChangePit,
  onChangeReportDate,
  onResetWasteDetail,
  addUploadDataTableProduction,
  onResetWasteBCM,
  onResetWasteBCMDetail
} = wasteRemovalSlice.actions;

export default wasteRemovalSlice.reducer;
