import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrls, get, post } from 'utils/request';

// initial state
export const initialState = {
  listRomConfiguration: []
};

export const getRomConfiguration = createAsyncThunk(
  "rom-excavator/configuration",
  async (params) => {
    const { contractor, search } = params

    const response = await get(`${apiUrls.romExcavatorConfiguration}?contractorName=${contractor}&search=${search}`);

    return response.data;
  }
);

export const saveRomConfiguration = createAsyncThunk(
  "rom-excavator/configuration-save",
  async (params) => {
    const response = await post(`${apiUrls.romExcavatorConfiguration}`, params);

    return response.data;
  }
);

// slicing
const romExcavatorConfiguration = createSlice({
  name: 'ACTUAL_ROM_CONFIGURATION',
  initialState,
  reducers: {
    onChangeLocation: (state, action) => {
      const payload = action.payload;

      state.listRomConfiguration[payload.index]["location"] = payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRomConfiguration.fulfilled, (state, action) => {
      let payload = action.payload;

      if (payload.length > 0) {
        payload = payload.sort((a, b) => (a.contractor > b.contractor) ? 1 : -1);
      }

      state.listRomConfiguration = payload;
    });
  },
});

export const romExcavatorConfigurationSelector = (state) => state.romExcavatorConfiguration.listRomConfiguration;

export const {
  onChangeLocation
} = romExcavatorConfiguration.actions

export default romExcavatorConfiguration.reducer;
