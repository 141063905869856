import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { apiUrls, deleteRequest, get, patch, post, put } from "utils/request";
import { ObjectWrapper, DATASOURCE_VALUE } from "utils/helper";

var _ = require("lodash");
const serviceName = "confRom";

export const initialState = {
  confRom: {
    loading: false,
    records: [],
    recordsTotal: "",
    error: "",
  },
  confRomMaster: {
    loading: false,
    records: [],
    recordsTotal: "",
    error: "",
  },
  confRomDetail: {
    loading: false,
    records: {
      romList: [],
      statusLogList: [{ dataStatus: "DRAFT" }],
      documentList: [],
      createdAt: "",
    },
    recordsTotal: "",
    error: "",
  },
  confRomLatest: {
    loading: false,
    records: [],
    error: '',
  },
};

export const getconfRomData = createAsyncThunk(
  "confRom/get",
  async (params) => {
    const response = await post(`${apiUrls.configRom}/filter`, {
      columns: [
        {
          data: "reportId",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "bibNotes",
          orderable: true,
          search: {
            regex: false,
            value: "",
          },
          searchValue: "",
          searchable: true,
        },
        {
          data: "dataStatus",
          orderable: true,
          search: {
            regex: params?.dataStatus === DATASOURCE_VALUE.waitingApproval || params?.dataStatus >= 20 ? true : false,
            value: '',
          },
          searchValue: params.dataStatus,
          searchable: true,
        },
        {
          data: "createdAt",
          orderable: true,
          search: {
            regex: true,
            value: "",
          },
          searchValue: params.date,
          searchable: true,
        },
      ],
      order: [],
      search: {
        regex: false,
        value: params.query || "",
      },
      draw: params?.pageIndex ? params.pageIndex : 1,
      length: params?.dataLength ? params.dataLength : -1,
      start: 0,
    });
    return response?.data;
  }
);

export const getDetailService = createAsyncThunk(
  `${serviceName}/detail`,
  async (params) => {
    const { id } = params;
    const response = await get(`${apiUrls.configRom}/${id}`);

    const sortRomListByRom = (data) => {
      return data.romList.sort((a, b) => {
        if (a.rom.rom < b.rom.rom) {
          return -1;
        }
        if (a.rom.rom > b.rom.rom) {
          return 1;
        }
        return 0;
      });
    };

    return {
      ...response?.data,
      romList: sortRomListByRom(response?.data),
    };
  }
);

export const getLatestRomConfig = createAsyncThunk(
  `${serviceName}/latest-rom-config`,
  async (params) => {
    const response = await get(`${apiUrls.configRom}/rom`);
    return response?.data;
  }
);

export const addService = createAsyncThunk(
  `${serviceName}/add`,
  async (param) => {
    const { data, username } = param;
    const response = await post(
      `${apiUrls.configRom}/save?userName=${username}`,
      data
    );
    return response.data;
  }
);

export const saveService = createAsyncThunk(
  `${serviceName}/save`,
  async (param) => {
    const { data, id, username } = param;
    const response = await put(
      `${apiUrls.configRom}/${id}/save?userName=${username}`,
      data
    );
    return response.data;
  }
);

export const submitService = createAsyncThunk(
  `${serviceName}/submit`,
  async (param) => {
    const { data, id, username } = param;
    const response = await put(
      `${apiUrls.configRom}/${id}/submit?userName=${username}`,
      data
    );
    return response.data;
  }
);

//tambah

export const uploadFileService = createAsyncThunk(
  `${serviceName}/uploadFile`,
  async (param) => {
    const { id, file } = param;
    const response = await post(`${apiUrls.configRom}/${id}/files`, file, {
      headers: { "Content-Type": undefined },
    });
    return response.data;
  }
);

export const deleteFileService = createAsyncThunk(
  `${serviceName}/deleteFile`,
  async (param) => {
    const { id, name } = param;
    const response = await deleteRequest(
      `${apiUrls.configRom}${id}/files/${name}`
    );
    return response.data;
  }
);

export const updateDataService = createAsyncThunk(
  `${serviceName}/adminUpdate`,
  async (param) => {
    const { id, action, username, data } = param;
    const response = await put(
      `${apiUrls.configRom}/${id}/${action}?userName=${username}`,
      data
    );
    return response.data;
  }
);

export const getconfRomMaster = createAsyncThunk(
  `${serviceName}/master`,
  async (params) => {
    const response = await post(apiUrls.mdRom.concat(`/filter`), {
      draw: 1,
      length: 50,
      start: 0,
    });
    var filtered = response.data.list.map((v) => {
      return {
        crusher: v,
        bib: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
        productivityTarget: 0,
        kepmen1827: {
          eu: 0,
          ma: 0,
          pa: 0,
          ua: 0,
        },
      };
    });
    response.data.list = filtered;
    return response?.data;
  }
);

export const intervensiConfRom = createAsyncThunk(
  `${serviceName}/intervensi`,
  async (params) => {
    let response

    try {
      response = await post(`${apiUrls.intervensiConfRom}/user-role?moduleName=Crushing`, params);
      return response?.data;
    }
    catch (err) {
      return err
    }
  }
);

const confRom = createSlice({
  name: "CONF_ROM",
  initialState,
  reducers: {
    onChangeTableInput: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confRomDetail.records.romList[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      )
      _.set(
        state.confRomMaster.records[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onChangeTableInputDetailConfRom: (state, action) => {
      const payload = action.payload;
      _.set(
        state.confRomDetail.records.romList[payload.tableIndexRow],
        payload.tableRowName,
        parseInt(payload.value)
      );
    },
    onReset: (state, action) => {
      state.confRomDetail = {
        loading: false,
        records: {
          romList: [],
          statusLogList: [{ dataStatus: "DRAFT" }],
          documentList: [],
          createdAt: "",
        },
        recordsTotal: "",
        error: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getconfRomData.pending, (state, action) => {
      state.confRom.loading = true;
    });
    builder.addCase(getconfRomData.fulfilled, (state, action) => {
      state.confRom.records = action.payload.list;
      state.confRom.recordsTotal = action.payload.recordsTotal;
      state.confRom.loading = false;
    });
    builder.addCase(getconfRomData.rejected, (state, action) => {
      state.confRom.loading = false;
      state.confRom.error = "Invalid get data";
    });
    builder.addCase(getconfRomMaster.pending, (state, action) => {
      state.confRomMaster.loading = true;
    });
    builder.addCase(getconfRomMaster.fulfilled, (state, action) => {
      state.confRomMaster.records = action.payload.list;
      state.confRomMaster.recordsTotal = action.payload.recordsTotal;
    });
    builder.addCase(getconfRomMaster.rejected, (state, action) => {
      state.confRomMaster.loading = false;
      state.confRomMaster.error = "Invalid get data";
    });
    builder.addCase(getDetailService.pending, (state, action) => {
      state.confRomDetail.loading = true;
    });
    builder.addCase(getDetailService.fulfilled, (state, action) => {
      if (!state.confRomDetail.records?.id) {
        state.confRomDetail.records = action.payload;
      } else {
        state.confRomDetail.records = {
          ...action.payload,
          romList: state.confRomDetail.records.romList,
        };
      }
    });
    builder.addCase(getDetailService.rejected, (state, action) => {
      state.confRomDetail.loading = false;
      state.confRomDetail.error = "Invalid get data";
    });
    builder.addCase(getLatestRomConfig.pending, (state, action) => {
      state.confRomLatest.loading = true;
    });
    builder.addCase(getLatestRomConfig.fulfilled, (state, action) => {
      const data = action.payload;
      const isDataValid = data?.length > 0;
      state.confRomLatest.loading = false;
      state.confRomLatest.records = isDataValid ? data : [];
    });
    builder.addCase(getLatestRomConfig.rejected, (state, action) => {
      state.confRomLatest.loading = false;
      state.confRomLatest.error = 'Invalid get data';
    });
  },
});
export const {
  onChangeTableInput,
  onChangeTableInputDetailConfRom,
  onReset,
} = confRom.actions;
export const confRomDataSelector = (state) => state.confRom.confRom;
export const confRomDetailSelector = (state) =>
  state.confRom.confRomDetail;
export const confRomMaster = (state) => state.confRom.confRomMaster;
export const confRomLatest = (state) => state.confRom.confRomLatest;

export default confRom.reducer;
